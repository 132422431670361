import React from "react";
import { generateTransactionBreadcrumbs } from "../../AccountPage";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { useLocation } from "react-router-dom";
import { UserTransactionTabs } from "./TransactionUserList";
import usePageTitle from "../../../global/PageTitle";

type TransactionUser = {
  id: number;
  code: string;
  shop: {
    id: number;
    photo: string;
    name: string;
  };
  product: Array<{
    id: number;
    photo: string;
    name: string;
    variant: string | null;
    qty: number;
    price: number;
  }>;
  shipping: {
    name: string;
    phone: number;
    courier: string;
    service: string;
    resi: string | null;
    address: string;
  };
  payment: {
    bank: string;
    type: string;
    number: string;
    url: string;
    expired: string;
  };
  shipping_cost: number;
  extra_cost: number;
  subtotal: number;
  fee: number;
  grand_total: number;
  status: string;
  date: string;
};

const TransactionUser: React.FC = () => {
  const location = useLocation();
  const { name } = (location.state as { name: string }) || { name: "" };
  const { id } = (location.state as { id: number }) || { id: 0 };

  // Determine user type based on the URL path
  const pathname = location.pathname;
  let userType = "pembeli"; // default

  if (pathname.includes("/pembeli")) {
    userType = "pembeli";
  } else if (pathname.includes("/penjual")) {
    userType = "penjual";
  } else if (pathname.includes("/ekspedisi")) {
    userType = "ekspedisi";
  }

  usePageTitle("Pengguna");

  return (
    <div>
      <PageTitle
        breadcrumbs={generateTransactionBreadcrumbs(userType)}
      ></PageTitle>
      <div className="card mb-xl-10">
        <UserTransactionTabs className="mb-xl-8" name={name} id={id} />
      </div>
    </div>
  );
};

export default TransactionUser;
