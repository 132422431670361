import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { FC } from "react";

interface UpdateKategoriModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpdateUser: (formData: any, id: number) => void;
  id: number | null;
  user?: {
    id: number;
    code: string;
    name: string;
    email: string;
    phone: string;
    is_cod?: boolean;
  } | null;
  formType:
    | "admin"
    | "manager"
    | "accounting"
    | "admin_exp"
    | "buyer"
    | "seller"
    | "exp";
}

const UpdateUserModal: FC<UpdateKategoriModalProps> = ({
  isOpen,
  onClose,
  handleUpdateUser,
  id,
  user,
  formType,
}) => {
  const [formData, setFormData] = useState({
    ...(formType === "exp" && { code: user?.code || "" }),
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    password: "",
    password_confirmation: "",
    ...(formType === "exp" && { is_cod: user?.is_cod || false }),
  });

  useEffect(() => {
    setFormData({
      ...(formType === "exp" && { code: user?.code || "" }),
      name: user?.name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      password: "",
      password_confirmation: "",
      ...(formType === "exp" && { is_cod: user?.is_cod || false }),
    });
  }, [user, formType]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleClose = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      ...(formType === "exp" && { code: "", is_cod: false }),
    });
    onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className="modal fade show d-block"
            id="kt_modalupdate_"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="fw-bolder">
                    {formType === "seller"
                      ? "Edit Penjual"
                      : formType === "buyer"
                      ? "Edit Pembeli"
                      : formType === "manager"
                      ? "Edit Manager"
                      : formType === "accounting"
                      ? "Edit Akutansi"
                      : formType === "admin_exp"
                      ? "Edit Admin Ekspedisi"
                      : "Edit Ekspedisi"}
                  </h2>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm btn-active-icon-primary"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <form
                    id="kt_modal_add_update_form"
                    className="form"
                    noValidate
                  >
                    {formType === "exp" && (
                      <>
                        <div className="fv-row mb-7">
                          <label className="fw-bold fs-6 mb-2">
                            Kode Ekspedisi
                          </label>
                          <input
                            placeholder="Masukkan kode expedisi"
                            className={clsx(
                              "form-control form-control-solid bg-light-dark mb-3 mb-lg-0"
                            )}
                            type="text"
                            value={formData.code}
                            name="code"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">Nama</label>
                      <input
                        placeholder="Masukkan nama kategori"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Nomor Telepon
                      </label>
                      <input
                        placeholder="Masukkan nama kategori"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                    <div className="fv-row mb-7">
                      <label className="required fw-bold fs-6 mb-2">
                        Email
                      </label>
                      <input
                        placeholder="Masukkan nama kategori"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                    <div className="fv-row mb-7">
                      <label className=" fw-bold fs-6 mb-2">Password</label>
                      <input
                        placeholder="Masukkan password"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                    <div className="fv-row mb-7">
                      <label className=" fw-bold fs-6 mb-2">
                        Konfirmasi Password
                      </label>
                      <input
                        placeholder="Masukkan konfirmasi password"
                        className={clsx(
                          "form-control form-control-solid mb-3 mb-lg-0"
                        )}
                        type="password"
                        name="password_confirmation"
                        value={formData.password_confirmation}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div>
                    {formType === "exp" && (
                      <div className="fv-row mb-7">
                        <div className="d-flex flex-stack">
                          <div className="me-5">
                            <label className="fs-6 fw-bold">COD</label>
                            <div className="fs-7 fw-semibold text-muted">
                              Aktifkan fitur COD untuk ekspedisi ini
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="form-check form-switch form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="is_cod"
                                checked={formData.is_cod}
                                onChange={handleChange}
                                id="cod_switch"
                              />
                              <label
                                className="form-check-label fw-semibold text-muted"
                                htmlFor="cod_switch"
                              >
                                {formData.is_cod ? "Aktif" : "Nonaktif"}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      if (id !== null) {
                        await handleUpdateUser(formData, id);
                        onClose();
                      }
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export { UpdateUserModal };
