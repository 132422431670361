import React, { useEffect, useState, useCallback } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import API from "../../../../../utils/apiProvider";
import { useAuth } from "../../../auth";
import { Link } from "react-router-dom";
import { AddPriceExpeditionModal } from "../modal/AddPriceExpeditionModal";
import { AlertMessage } from "../../../global/AlertMessage";
import { DeleteModal } from "../../../global/DeleteModal";
import usePageTitle from "../../../global/PageTitle";
import { Empty, Spin } from "antd";
import { UpdatePriceExpeditionModal } from "./ModalUpdatePriceExp";

interface Data {
  id: number;
  label: string;
}

type Props = {
  className: string;
  name: string;
  code: string;
  id: number;
};

type PriceExpedition = {
  id: number;
  label: string;
  color: string;
  have_price: boolean;
};

const PriceListExpedition: React.FC<Props> = ({
  className,
  name,
  code,
  id,
}) => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const [loading, setLoading] = useState(true);
  const [listExp, setListExp] = useState<PriceExpedition[]>([]);
  const [zeroData, setZeroData] = useState("");
  const [alert, setAlert] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState<PriceExpedition | null>(
    null
  );
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  usePageTitle("Pengguna");

  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => setAlert(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [alert]);

  const fetchTransactionData = useCallback(async () => {
    setLoading(true);
    if (id !== 0) {
      const resTransactionUser = await API.GetPriceExpedition(token, id);

      if (
        resTransactionUser.status === 200 &&
        resTransactionUser.data.data.length > 0
      ) {
        const sortedData = resTransactionUser.data.data.sort(
          (a: PriceExpedition, b: PriceExpedition) => a.id - b.id
        );
        setListExp(sortedData);
        setZeroData("");
      } else {
        setListExp([]);
        setZeroData(resTransactionUser.data?.message || "No data available");
        await addDefaultPrices();
      }
    } else {
      console.error("No transaction ID found in location state");
      setListExp([]);
      setZeroData("Failed to fetch data");
    }

    setLoading(false);
  }, [id, token]);

  const addDefaultPrices = async () => {
    setLoading(true);

    const defaultColors = [
      "#445569",
      "#4473c5",
      "#ed7d31",
      "#a5a5a5",
      "#ffc000",
      "#70ad46",
    ];
    const defaultPrices = defaultColors.map((color, index) => ({
      label: `Harga ${index + 1}`,
      color: color,
    }));

    if (listExp.length === 0) {
      for (const price of defaultPrices) {
        const formDataAPI = new FormData();
        formDataAPI.append("label", price.label);
        formDataAPI.append("color", price.color);

        await API.AddPriceExp(formDataAPI, id, token);
      }

      await fetchTransactionData();
      setAlert({
        type: "success",
        message: "Harga berhasil ditambahkan",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTransactionData();
  }, [fetchTransactionData]);

  const handleAddPrice = async (formData: any) => {
    setLoading(true);
    try {
      const { label, color } = formData;
      const formDataAPI = new FormData();
      formDataAPI.append("label", label);
      formDataAPI.append("color", color);

      const resAddPriceExp = await API.AddPriceExp(formDataAPI, id, token);
      if (resAddPriceExp.status === 200) {
        await fetchTransactionData();
        setAlert({ type: "success", message: resAddPriceExp.data.message });
      } else {
        setAlert({ type: "error", message: resAddPriceExp.data.message });
      }
    } catch (error) {
      console.error("Error adding price", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePrice = async (priceId: number) => {
    try {
      const resDeletePrice = await API.DeletePriceExp(id, priceId, token);
      if (resDeletePrice.status === 200) {
        await fetchTransactionData();
        setAlert({ type: "success", message: resDeletePrice.message });
      } else {
        setAlert({ type: "error", message: resDeletePrice.data.message });
      }
    } catch (error) {
      console.error("Error deleting price", error);
    }
  };

  const handleUpdatePrice = async (formData: {
    label: string;
    color: string;
  }) => {
    if (selectedPrice) {
      setLoading(true);
      const formDataAPI = new FormData();
      formDataAPI.append("label", formData.label);
      formDataAPI.append("color", formData.color);

      const resUpdatePriceExp = await API.UpdatePriceExp(
        formDataAPI,
        id,
        selectedPrice.id,
        token
      );
      if (resUpdatePriceExp.status === 200) {
        await fetchTransactionData();
        setAlert({ type: "success", message: resUpdatePriceExp.data.message });
        setSelectedPrice(null);
      } else {
        setAlert({ type: "error", message: resUpdatePriceExp.data.message });
      }
      setLoading(false);
    }
  };

  return (
    <div className="col">
      {alert && <AlertMessage type={alert.type} message={alert.message} />}
      <div className={`card ${className}`}>
        <div className="card-header border-0 pt-5">
          <div className="d-flex align-items-center">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Daftar Harga ({code})
              </span>
            </h3>
          </div>
        </div>

        <div className="separator separator my-4"></div>

        <div className="card-header border-0">
          <div className="card-toolbar d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-sm btn-light-primary me-4"
              onClick={() => window.history.back()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr002.svg"
                className="svg-icon-2"
              />
            </button>
            {/* <button
              type="button"
              className="btn btn-sm btn-light-primary me-4"
              onClick={() => setIsModalOpen(true)}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              Tambah Label
            </button> */}
            {isModalOpen && (
              <AddPriceExpeditionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                handleAddPrice={handleAddPrice}
              />
            )}
            {isUpdateModalOpen && selectedPrice && (
              <UpdatePriceExpeditionModal
                isOpen={isUpdateModalOpen}
                onClose={() => setIsUpdateModalOpen(false)}
                handleUpdatePrice={handleUpdatePrice}
                initialData={{
                  label: selectedPrice.label,
                  color: selectedPrice.color,
                }}
              />
            )}
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase bg-light text-center">
                  <th className="min-w-40px rounded-start text-center">No</th>
                  <th className="min-w-125px text-center">Nama</th>
                  <th className="min-w-125px text-center ">Setup Harga</th>
                  <th className="min-w-40px text-center rounded-end">Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : listExp.length > 0 ? (
                  listExp.map((price, index) => (
                    <tr key={price.id}>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {index + 1}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className={`text-${price.color} text-center`}>
                        <span
                          className={`fw-bold text-${price.color} mb-1 fs-6`}
                          style={{ color: price.color }}
                        >
                          {price.label}
                        </span>
                      </td>
                      <td className="text-center">
                        <Link
                          to={`/pengguna/ekspedisi/price/${price.id}`}
                          state={{
                            id1: id,
                            id: price.id,
                            name,
                            label: price.label,
                            code,
                          }}
                          className="btn btn-sm btn-light-primary"
                        >
                          <KTSVG
                            path="/media/icons/duotune/coding/cod001.svg"
                            className="svg-icon-2"
                          />
                          Setup Harga
                        </Link>
                      </td>
                      <td className="text-center">
                        <span
                          className={`badge ${
                            price.have_price ? "bg-success" : "bg-danger"
                          } text-light fw-bold`}
                        >
                          {price.have_price ? "Terisi" : "Belum Terisi"}
                        </span>

                        {/* <button
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                          onClick={() => openUpdateModal(price)}
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button> */}
                        {/* <button
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#kt_modal_${price.id}`}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </button> */}
                        <DeleteModal
                          id={price.id}
                          name={price.label}
                          onDelete={handleDeletePrice}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PriceListExpedition };
