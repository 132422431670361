// AuthHelpers.ts
import apiProvider from "../../../../utils/apiProvider";
import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "authUser";

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return undefined;
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return undefined;
  }

  let auth;
  try {
    auth = JSON.parse(lsValue);
  } catch (error) {
    return undefined;
  }

  return auth || undefined;
};

const setAuth = (auth: AuthModel): boolean => {
  if (!localStorage) {
    return false;
  }

  const lsValue = JSON.stringify(auth);
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  return true;
};

const removeAuth = (): boolean => {
  if (!localStorage) {
    return false;
  }

  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  return true;
};

const handleLogout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

const refreshToken = async (): Promise<boolean> => {
  const auth = getAuth();
  const refreshTokenValue = auth?.refreshToken;

  if (!refreshTokenValue) {
    return false;
  }

  const data = {
    refresh_token: refreshTokenValue
  };

  let response;
  try {
    response = await apiProvider.Refresh(data);
  } catch {
    return false;
  }

  if (response?.status === 200 && response?.data?.data?.token) {
    const newAuth = {
      ...auth,
      token: response.data.data.token,
    };

    setAuth(newAuth);

    return true;
  }

  return false;
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";

  axios.interceptors.request.use(
    (config: {
      headers: { Authorization: string };
      url?: string;
      method?: string;
      data?: any;
    }) => {
      const auth = getAuth();
      if (auth?.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      }
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  let isRefreshing = false;
  let failedQueue: any[] = [];

  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        isRefreshing = true;

        const refreshSuccess = await refreshToken();

        if (refreshSuccess) {
          const newToken = getAuth()?.token;
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          processQueue(null, newToken);
          isRefreshing = false;
          return axios(originalRequest);
        } else {
          processQueue(new Error("Refresh token failed"), null);
          isRefreshing = false;
          handleLogout();
          return Promise.reject(
            new Error("Authentication failed. You have been logged out.")
          );
        }
      }

      return Promise.reject(error);
    }
  );
}

export {
  getAuth,
  setAuth,
  removeAuth,
  AUTH_LOCAL_STORAGE_KEY,
  handleLogout,
  refreshToken,
};
