import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Skeleton } from "antd";

const EditVersion: React.FC = () => {
  const [loading, setLoading] = useState<number | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showAlertError, setShowAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { currentUser } = useAuth();
  const token = currentUser?.token;

  const [versions, setVersions] = useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (token) {
      getVersion();
    }
  }, [token]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlertError]);

  const getVersion = async () => {
    setLoading(null);
    setDataLoading(true);
    const resVersionData = await API.GetVersion(token);

    if (resVersionData.status === 200 && resVersionData.data.data.length > 0) {
      const sortedVersions = resVersionData.data.data.sort(
        (a: any, b: any) => a.id - b.id
      );
      setVersions(sortedVersions);
    } else {
      console.error("Error fetching version data:", resVersionData);
    }
    setDataLoading(false);
  };

  const handleUpdateVersion = async (
    event: React.FormEvent,
    versionId: number
  ) => {
    event.preventDefault();
    setLoading(versionId);

    const selectedVersion = versions.find(
      (version) => version.id === versionId
    );
    if (!selectedVersion) return;

    const {
      app,
      android_version,
      android_code,
      ios_version,
      ios_code,
      url_playstore,
      url_appstore,
      web_version,
      web_code,
    } = selectedVersion;

    const formDataAPI = new FormData();

    if (android_version) {
      formDataAPI.append("android_version", android_version || "");
      formDataAPI.append(
        "android_code",
        android_code !== undefined ? android_code.toString() : ""
      );
      formDataAPI.append("ios_version", ios_version || "");
      formDataAPI.append(
        "ios_code",
        ios_code !== undefined ? ios_code.toString() : ""
      );
      formDataAPI.append("url_playstore", url_playstore || "");
      formDataAPI.append("url_appstore", url_appstore || "");
    } else if (web_version) {
      formDataAPI.append("web_version", web_version || "");
      formDataAPI.append(
        "web_code",
        web_code !== undefined ? web_code.toString() : ""
      );
    }

    const res = await API.UpdateVersion(formDataAPI, token, versionId);

    if (res.status === 200) {
      await getVersion();
      setShowAlert(true);
      setSuccessMessage(res.data.message);
    } else {
      setErrorMessage(res.message);
      setShowAlertError(true);
    }
    setLoading(null);
  };

  const handleInputChange = (
    versionId: number,
    fieldName: string,
    value: string | number
  ) => {
    setVersions((prevVersions) =>
      prevVersions.map((version) =>
        version.id === versionId ? { ...version, [fieldName]: value } : version
      )
    );
  };

  return (
    <>
      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}

      {versions.map((version) => (
        <div key={version.id} className="card mb-5 mb-xl-10">
          <div className="card-header border-0">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{version.app}</h3>
            </div>
          </div>

          <div className="card-body border-top">
            <form
              className="form"
              noValidate
              onSubmit={(e) => handleUpdateVersion(e, version.id)}
            >
              {version.web_version && (
                <>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Web Version
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan web versi"
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        value={version.web_version}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "web_version",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Web Code
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan web code"
                        className="form-control form-control-lg form-control-solid"
                        type="number"
                        value={version.web_code}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "web_code",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    )}
                  </div>
                </>
              )}
              {version.android_version && (
                <>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Android Version
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan android versi"
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        value={version.android_version}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "android_version",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Android Code
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan android code"
                        className="form-control form-control-lg form-control-solid"
                        type="number"
                        value={version.android_code}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "android_code",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      iOS Version
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan iOS versi"
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        value={version.ios_version}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "ios_version",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      iOS Code
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan iOS code"
                        className="form-control form-control-lg form-control-solid"
                        type="number"
                        value={version.ios_code}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "ios_code",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Url Playstore
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan url Playstore"
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        value={version.url_playstore}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "url_playstore",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Url Appstore
                    </label>
                    {loading === version.id ? (
                      <Skeleton.Input
                        style={{ width: "100%", height: 46 }}
                        size="large"
                        active
                        block
                      />
                    ) : (
                      <input
                        placeholder="Masukkan url Appstore"
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        value={version.url_appstore}
                        onChange={(e) =>
                          handleInputChange(
                            version.id,
                            "url_appstore",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                </>
              )}
              <div className="d-flex">
                <button
                  type="submit"
                  className="btn btn-primary me-2 px-6"
                  disabled={loading === version.id}
                >
                  {loading === version.id
                    ? "Menyimpan..."
                    : "Simpan Perubahan"}
                </button>
              </div>
            </form>
          </div>
        </div>
      ))}
    </>
  );
};

export { EditVersion };
