import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import OneSignal from "react-onesignal";

const App = () => {
  useEffect(() => {
    const initializeNotifications = async () => {
      try {
        if (!OneSignal.Notifications.isPushSupported()) {
          return;
        }

        const permission = OneSignal.Notifications.permission;

        if (!permission) {
          await OneSignal.Notifications.requestPermission();
        }

        OneSignal.Notifications.addEventListener(
          "foregroundWillDisplay",
          handleForegroundNotification
        );
        OneSignal.Notifications.addEventListener(
          "click",
          handleNotificationClick
        );
        OneSignal.Notifications.addEventListener(
          "permissionChange",
          handlePermissionChange
        );

        const id = OneSignal.User.PushSubscription.id;
        if (id) {
          localStorage.setItem("oneSignalSubscriptionId", id);
        }

        if (!OneSignal.User.PushSubscription.optedIn) {
          await OneSignal.User.PushSubscription.optIn();
        }
      } catch (error) {
        console.error("Error initializing notifications:", error);
      }
    };

    const handleForegroundNotification = (event: any) => {
      console.log("Notification will display:", event);
    };

    const handleNotificationClick = (event: any) => {
      if (event.result.url) {
        window.open(event.result.url);
      }
    };

    const handlePermissionChange = (permission: any) => {
      console.log("Permission changed:", permission);
    };

    const handleSubscriptionChange = (event: any) => {
      console.log("OneSignal Subscription changed:", event.current);
    };

    initializeNotifications();
    OneSignal.User.PushSubscription.addEventListener(
      "change",
      handleSubscriptionChange
    );

    return () => {
      OneSignal.Notifications.removeEventListener(
        "foregroundWillDisplay",
        handleForegroundNotification
      );
      OneSignal.Notifications.removeEventListener(
        "click",
        handleNotificationClick
      );
      OneSignal.Notifications.removeEventListener(
        "permissionChange",
        handlePermissionChange
      );
      OneSignal.User.PushSubscription.removeEventListener(
        "change",
        handleSubscriptionChange
      );
    };
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
