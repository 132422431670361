import React, { useState, useEffect, useCallback } from "react";
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import idLocale from "@fullcalendar/core/locales/id";
import "./fullcalendar.bundle.css";
import apiProvider from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import EventModal from "./EventModal";
import EventDetailsModal from "./DetailEvent";
import { AlertMessage } from "../../global/AlertMessage";
import EditEventModal from "./EditModalEvent";

type Props = {
  className: string;
};

interface Event {
  id: number;
  name: string;
  date: string;
}

const CalendarComponents: React.FC<Props> = ({ className }) => {
  const [currentEvents, setCurrentEvents] = useState<Event[]>([]);
  const [alert, setAlert] = useState<{
    type: "success" | "error";
    message: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [zeroData, setZeroData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState<{
    start: string;
    end: string;
  } | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<{
    id: number;
    title: string;
    date: string;
  } | null>(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [eventToEdit, setEventToEdit] = useState<{
    id: number;
    title: string;
    date: string;
  } | null>(null);
  const { currentUser } = useAuth();
  const token = currentUser?.token;

  useEffect(() => {
    if (token) fetchHolidays();
  }, [token]);

  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => setAlert(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [alert]);

  const fetchHolidays = async () => {
    setLoading(true);
    const res = await apiProvider.GetHoliday(token);
    if (res.status === 200 && Array.isArray(res.data.data)) {
      setCurrentEvents(res.data.data);
    } else {
      setCurrentEvents([]);
      setZeroData(res.data.message || "Tidak ada data tersedia");
    }
    setLoading(false);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setSelectedDate({ start: selectInfo.startStr, end: selectInfo.endStr });
    setModalShow(true);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedEvent({
      id: clickInfo.event.id as unknown as number,
      title: clickInfo.event.title,
      date: clickInfo.event.startStr,
    });
    setDetailsModalShow(true);
  };

  const handleEditEvent = () => {
    if (selectedEvent) {
      setEventToEdit(selectedEvent);
      setDetailsModalShow(false);
      setEditModalShow(true);
    }
  };

  const formatDateToYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSaveEvent = async (title: string, start: string) => {
    if (!token) return;

    setLoading(true);
    const formattedStartDate = formatDateToYYYYMMDD(new Date(start));
    const formData = new FormData();
    formData.append("name", title);
    formData.append("date", formattedStartDate);

    const res = await apiProvider.AddHoliday(formData, token);
    handleApiResponse(res, "Event added successfully");
    setModalShow(false);
  };

  const handleEventDrop = async (dropInfo: EventDropArg) => {
    const { event } = dropInfo;
    const eventId = event.id.toString();
    const newDate = formatDateToYYYYMMDD(event.start!);
    const eventName = event.title;

    const updatedEvents = currentEvents.map((evt) =>
      evt.id.toString() === eventId
        ? { ...evt, date: newDate, name: eventName }
        : evt
    );

    setCurrentEvents(updatedEvents);

    if (token) {
      const formData = new FormData();
      formData.append("date", newDate);
      formData.append("name", eventName);

      const res = await apiProvider.UpdateHoliday(formData, token, eventId);
      handleApiResponse(res, "Event updated successfully");
    }
  };

  const handleUpdateEvent = async (title: string, date: string) => {
    if (!token || !eventToEdit) return;

    setLoading(true);
    const formattedDate = formatDateToYYYYMMDD(new Date(date));
    const formData = new FormData();
    formData.append("name", title);
    formData.append("date", formattedDate);

    const res = await apiProvider.UpdateHoliday(
      formData,
      token,
      eventToEdit.id.toString()
    );
    handleApiResponse(res.data.message, "Event updated successfully");
    setEditModalShow(false);
  };

  const handleDeleteEvent = async () => {
    if (!token || !selectedEvent) return;

    setLoading(true);
    const res = await apiProvider.DeleteHoliday(
      token,
      selectedEvent.id.toString()
    );
    handleApiResponse(res, "Event deleted successfully");
    setDetailsModalShow(false);
  };

  const handleApiResponse = (res: any, successMessage: string) => {
    if (res) {
      fetchHolidays();
      setAlert({
        type: "success",
        message: res.data.message || successMessage,
      });
    } else {
      setAlert({
        type: "error",
        message: res.data.message || "Operation failed",
      });
    }
    setLoading(false);
  };

  const handleEvents = useCallback((events: EventApi[]) => {}, []);

  const eventSources = currentEvents.map((event) => ({
    id: event.id.toString(),
    title: event.name,
    start: event.date,
    end: event.date,
    allDay: true,
    backgroundColor: "#f1416c",
    borderColor: "#f1416c",
    color: "#fff",
  }));

  return (
    <div className={`card ${className}`}>
      {alert && <AlertMessage type={alert.type} message={alert.message} />}
      <div className="card-header">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Kalender Baba</h3>
        </div>
        <div className="card-toolbar">
          <button
            className="btn btn-flex btn-sm btn-danger"
            data-kt-calendar="add"
            onClick={() => {
              setModalShow(true);
              setSelectedDate({
                start: new Date().toISOString(),
                end: new Date().toISOString(),
              });
            }}
          >
            <i className="ki-duotone ki-plus fs-2"></i>Tambah Hari Libur
          </button>
        </div>
      </div>
      <div className="card-body">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "",
          }}
          buttonText={{
            today: "Hari Ini",
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          events={eventSources}
          eventsSet={handleEvents}
          locale={idLocale}
          eventDrop={handleEventDrop}
        />
        {selectedDate && (
          <EventModal
            show={modalShow}
            handleClose={() => setModalShow(false)}
            handleSave={handleSaveEvent}
            selectedDate={selectedDate}
          />
        )}
        {selectedEvent && (
          <EventDetailsModal
            show={detailsModalShow}
            handleClose={() => setDetailsModalShow(false)}
            eventTitle={selectedEvent.title}
            eventDate={selectedEvent.date}
            onEdit={handleEditEvent}
            onDelete={handleDeleteEvent}
          />
        )}
        {eventToEdit && (
          <EditEventModal
            show={editModalShow}
            handleClose={() => setEditModalShow(false)}
            handleSave={handleUpdateEvent}
            eventTitle={eventToEdit.title}
            eventDate={eventToEdit.date}
          />
        )}
      </div>
    </div>
  );
};

const renderEventContent = (eventContent: EventContentArg) => (
  <>
    <b>{eventContent.timeText}</b>
    <span className="fw-bold d-block mb-1 fs-7 ms-2">
      {eventContent.event.title}
    </span>
  </>
);

export { CalendarComponents };
