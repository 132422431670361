/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      {/* Utama */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Utama
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItemWithSub
        to="/pengguna"
        title="Pengguna"
        icon="/media/icons/duotune/communication/com006.svg"
        fontIcon="bi-person"
      >
        {currentUser?.role === "super_admin" && (
          <SidebarMenuItem
            to="/pengguna/admin"
            title="Admin"
            hasBullet={true}
          />
        )}
        <SidebarMenuItem
          to="/pengguna/manager"
          title="Manager"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/pengguna/akunting"
          title="Akunting"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/pengguna/adminekspedisi"
          title="Admin Ekspedisi"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/pengguna/ekspedisi"
          title="Ekspedisi"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/pengguna/pembeli"
          title="Pembeli"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/pengguna/penjual"
          title="Penjual"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      {/* End Utama */}

      {/* Marketplace */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Marketplace
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/marketplace/toko"
        icon="/media/icons/duotune/ecommerce/ecm004.svg"
        title="Toko"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/marketplace/kategori"
        icon="/media/icons/duotune/ecommerce/ecm009.svg"
        title="Kategori"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/marketplace/semuaproduk"
        icon="/media/icons/duotune/ecommerce/ecm005.svg"
        title="Produk"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/marketplace/transaksi"
        icon="/media/icons/duotune/graphs/gra001.svg"
        title="Transaksi"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/marketplace/komplaintransaksi"
        icon="/media/icons/duotune/graphs/gra001.svg"
        title="Transaksi Komplain"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/marketplace/percakapan/pembeli"
        title="Percakapan"
        icon="/media/icons/duotune/communication/com012.svg"
        fontIcon="bi-chat-left"
      />
      <SidebarMenuItem
        to="/marketplace/penarikan"
        icon="/media/icons/duotune/finance/fin008.svg"
        title="Penarikan"
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/marketplace/laporanpendapatan"
        icon="/media/icons/duotune/general/gen005.svg"
        title="Laporan Pendapatan"
        fontIcon="bi-app-indicator"
      />
      {/* End Marketplace */}

      {/* Pengaturan */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Pengaturan
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="/pengaturan/pemberitahuan"
        icon="/media/icons/duotune/communication/com004.svg"
        title="Pemberitahuan"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/pengaturan/biayapenanganan"
        icon="/media/icons/duotune/finance/fin010.svg"
        title="Biaya Penanganan"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/pengaturan/pembayaran"
        icon="/media/icons/duotune/finance/fin002.svg"
        title="Pembayaran"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/pengaturan/kalenderbaba"
        icon="/media/icons/duotune/general/gen014.svg"
        title="Kalender Baba"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/pengaturan/banner"
        icon="/media/icons/duotune/general/gen006.svg"
        title="Banner"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/pengaturan/akun"
        icon="/media/icons/duotune/general/gen019.svg"
        title="Akun"
        fontIcon="bi-layers"
      />
      {currentUser?.role === "super_admin" && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Dev Zone
              </span>
            </div>
          </div>
          <SidebarMenuItem
            to="/dev/versi"
            icon="/media/icons/duotune/electronics/elc002.svg"
            title="Versi"
            fontIcon="bi-layers"
          />
          <SidebarMenuItem
            to="/dev/log"
            icon="/media/icons/duotune/coding/cod003.svg"
            title="Audit Log"
            fontIcon="bi-layers"
          />
        </>
      )}

      {/* Logger */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Logger
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="/log"
        icon="/media/icons/duotune/coding/cod003.svg"
        title="Error Log"
        fontIcon="bi-layers"
      /> */}
    </>
  );
};

export { SidebarMenuMain };
