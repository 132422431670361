import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  KTCardBody,
  KTSVG,
  toAbsoluteUrl,
} from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";
import { Link } from "react-router-dom";
import { Pagination } from "../../global/Pagination";
import { FormatDesc } from "../../global/FormatDescription";
import SearchInput from "../../global/SearchInput";
import { DeleteModal } from "../../global/DeleteModal";
import { Empty, Image, Spin } from "antd";
import { debounce } from "lodash";

interface ShopData {
  id: number;
  photo: string;
  name: string;
  phone: string;
  owner: string;
  address: string;
  description: string | null;
  is_verified: boolean;
  is_active: boolean;
  is_cod: boolean;
  is_cod_active: boolean;
  is_cod_blacklist: boolean;
}

interface MetaData {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

interface AlertState {
  show: boolean;
  message: string;
  type: "success" | "error";
}

const Shop: React.FC<{ className: string }> = ({ className }) => {
  const { currentUser } = useAuth();
  const [shopData, setShopData] = useState<ShopData[]>([]);
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: "",
    type: "success",
  });
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [zeroData, setZeroData] = useState("");

  const startIndex = useMemo(
    () => (meta.page - 1) * meta.per_page,
    [meta.page, meta.per_page]
  );

  const showAlert = useCallback(
    (type: "success" | "error", message: string) => {
      setAlert({ show: true, type, message });
      const timer = setTimeout(() => {
        setAlert((prev) => ({ ...prev, show: false }));
      }, 4000);
      return () => clearTimeout(timer);
    },
    []
  );

  const getShop = useCallback(async () => {
    if (!currentUser?.token) return;

    setLoading(true);
    const response = await API.GetShop(
      currentUser.token,
      meta.page,
      searchQuery,
      meta.per_page
    );

    if (response.status === 200 && response.data.data.length > 0) {
      setShopData(response.data.data);
      setMetaData(response.data.meta);
      setZeroData("");
    } else {
      setShopData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(response.data.message);
    }
    setLoading(false);
  }, [currentUser?.token, meta.page, meta.per_page, searchQuery, showAlert]);

  useEffect(() => {
    getShop();
  }, [getShop]);

  const handleDeleteShop = async (id: number) => {
    if (!currentUser?.token) return;

    const response = await API.DeleteShop(currentUser.token, id);
    if (response.status === 200) {
      await getShop();
      showAlert("success", response.data.message);
    } else {
      showAlert("error", response.data.message);
    }
  };

  const handleToggleActive = async (id: number) => {
    if (!currentUser?.token) return;

    const response = await API.UpdateStatusShop(currentUser.token, id);
    if (response.status === 200) {
      setShopData((prev) =>
        prev.map((shop) =>
          shop.id === id ? { ...shop, is_active: !shop.is_active } : shop
        )
      );
      showAlert("success", response.data.message);
    } else {
      showAlert("error", response.data.message);
    }
  };

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
        setMetaData((prev) => ({ ...prev, page: pageNumber }));
      }
    },
    [meta.total_pages]
  );

  const handleRecordsPerPageChange = useCallback((recordsPerPage: number) => {
    setMetaData((prev) => ({
      ...prev,
      page: 1,
      per_page: recordsPerPage,
    }));
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
        setMetaData((prev) => ({ ...prev, page: 1 }));
      }, 500),
    []
  );

  const handleExport = async () => {
    if (!currentUser?.token || loading) return;

    setLoading(true);
    try {
      const response = await API.GetExportShop(currentUser.token);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "shops.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showAlert("success", "File berhasil diunduh");
      }
    } catch (error) {
      showAlert("error", "Gagal mengekspor toko");
    } finally {
      setLoading(false);
    }
  };

  const renderTableRow = useCallback(
    (shop: ShopData, index: number) => (
      <tr key={shop.id}>
        <td className="text-center">
          <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
            {startIndex + index + 1}
          </span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div className="symbol symbol-60px me-5">
              <Image
                src={shop.photo}
                className="rounded"
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "contain",
                }}
              />
            </div>
            <span className="text-dark fw-bold fs-6 me-2">
              {shop.name}
              {shop.is_verified && (
                <img
                  alt="Verified"
                  src={toAbsoluteUrl("/media/logos/ic-verived.svg")}
                  className="h-14px app-sidebar-logo-default ms-2"
                />
              )}
              {shop.is_cod && (
                <div
                  className={`badge fw-bold fs-8 ms-2 ${
                    shop.is_cod_blacklist
                      ? "badge-light-dark"
                      : shop.is_cod_active
                      ? "badge-light-danger"
                      : "badge-light-dark"
                  }`}
                  style={{
                    textDecoration: shop.is_cod_blacklist
                      ? "line-through"
                      : "none",
                  }}
                >
                  Bisa COD
                </div>
              )}
            </span>
          </div>
        </td>
        <td className="text-center">
          <span className="text-muted fw-bold mb-1 fs-6">{shop.phone}</span>
        </td>
        <td className="text-center">
          <span className="text-muted fw-bold mb-1 fs-6">{shop.owner}</span>
        </td>
        <td className="text-center">
          <span className="text-muted fw-bold mb-1 fs-6">{shop.address}</span>
        </td>
        <td>
          <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
            <input
              className="form-check-input h-30px w-60px"
              checked={shop.is_active}
              onChange={() => handleToggleActive(shop.id)}
              type="checkbox"
            />
          </div>
        </td>
        <td className="text-center">
          <Link
            to="/marketplace/toko/detail"
            state={{ ...shop }}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen032.svg"
              className="svg-icon-2"
            />
          </Link> 
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            type="button"
            data-bs-toggle="modal"
            data-bs-target={`#kt_modal_${shop.id}`}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
          <DeleteModal
            id={shop.id}
            name={shop.name}
            onDelete={handleDeleteShop}
          />
        </td>
      </tr>
    ),
    [handleToggleActive, startIndex]
  );

  return (
    <div className={`card ${className}`}>
      {alert.show && <AlertMessage type={alert.type} message={alert.message} />}

      {/* Header */}
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">List Toko</h3>
        </div>
      </div>

      <div className="separator separator"></div>

      {/* Toolbar */}
      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center justify-content-between w-100">
          <button
            type="button"
            className="btn btn-sm btn-light-primary"
            onClick={handleExport}
            disabled={loading}
          >
            <KTSVG
              path="/media/icons/duotune/files/fil021.svg"
              className="svg-icon-2"
            />
            Ekspor Toko
          </button>
          <div className="d-flex align-items-center position-relative">
            <SearchInput placeholder="Search" onSearch={debouncedSearch} />
          </div>
        </div>
      </div>

      {/* Table */}
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5">
            <thead>
              <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                <th className="min-w-60px rounded-start">No</th>
                <th className="min-w-250px">Nama</th>
                <th className="min-w-125px">Nomor Telepon</th>
                <th className="min-w-125px">Pemilik</th>
                <th className="min-w-125px">Alamat</th>
                <th className="min-w-125px">Status</th>
                <th className="pe-4 min-w-125px rounded-end">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={8} className="text-center">
                    <Spin />
                  </td>
                </tr>
              ) : shopData.length > 0 ? (
                shopData.map((shop, index) => renderTableRow(shop, index))
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    {zeroData && (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            totalRecords={meta.total}
            recordsPerPage={meta.per_page}
            currentPage={meta.page}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
          />
        </div>
      </KTCardBody>
    </div>
  );
};

export { Shop };
