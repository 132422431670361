import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../auth";
import API from "../../../utils/apiProvider";
import { Pagination } from "../global/Pagination";
import { Empty, Spin } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { Indonesian } from "flatpickr/dist/l10n/id";
import { FilterAction } from "./FilterAction";

// Types and Interfaces
interface AuditLogEntry {
  id: number;
  user: {
    id: number;
    role: string;
    name: string;
  };
  action: string;
  description: string;
  performed_at: string;
}

interface MetaData {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

interface UserData {
  id: number;
  photo: string;
  code: string;
  name: string;
  email: string;
  phone: string;
  join_date: string;
  last_activity: string;
  is_active: boolean;
}

// Constants
const defaultMeta: MetaData = {
  page: 1,
  per_page: 10,
  total: 0,
  total_pages: 0,
};

// Helper Functions
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day} ${month} ${year}, ${hours}:${minutes}`;
};

const formatDescription = (description: string) => {
  try {
    const startIndex = description.indexOf("{");
    const endIndex = description.lastIndexOf("}");

    if (startIndex === -1 || endIndex === -1) return description;

    const prefix = description.substring(0, startIndex);
    const jsonString = description.substring(startIndex, endIndex + 1);
    const suffix = description.substring(endIndex + 1);

    const parsedJson = JSON.parse(jsonString);

    return (
      <div className="description-container">
        <div className="d-flex flex-column gap-2">
          {prefix && <span className="text-muted">{prefix.trim()}</span>}
          <div className="json-container p-3 rounded border">
            <JSONPretty
              data={parsedJson}
              theme={{
                main: "line-height:1.3;color:var(--kt-text-gray-800);background:var(--kt-gray-100);overflow:auto;",
                error:
                  "line-height:1.3;color:var(--kt-danger);background:var(--kt-danger-light);overflow:auto;",
                key: "color:var(--kt-primary);",
                string: "color:var(--kt-success);",
                value: "color:var(--kt-success);",
                boolean: "color:var(--kt-info);",
              }}
            />
          </div>
          {suffix && <span className="text-muted">{suffix.trim()}</span>}
        </div>
      </div>
    );
  } catch (e) {
    return description;
  }
};

const timeAgo = (dateString: string): string => {
  const now = new Date();
  const date = new Date(dateString);
  const diffInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60)
  );

  if (diffInMinutes < 1) return "Baru saja";
  if (diffInMinutes < 60) return `${diffInMinutes} menit yang lalu`;
  if (diffInMinutes < 1440)
    return `${Math.floor(diffInMinutes / 60)} jam yang lalu`;
  return `${Math.floor(diffInMinutes / 1440)} hari yang lalu`;
};

// Main Component
const AuditLogPage: React.FC = () => {
  const { currentUser } = useAuth();
  const token = currentUser?.token;
  const flatpickrRef = useRef<any>(null);

  // State management
  const [loading, setLoading] = useState(false);
  const [logData, setLogData] = useState<AuditLogEntry[]>([]);
  const [userData, setUserData] = useState<UserData[]>([]);
  const [zeroData, setZeroData] = useState("");
  const [meta, setMeta] = useState<MetaData>(defaultMeta);
  const [startIndex, setStartIndex] = useState(0);

  // Filter states
  const [userId, setUserId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [action, setAction] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch audit logs whenever dependencies change
  useEffect(() => {
    if (token) {
      getAuditLogEntry();
      getUsers();
    }
  }, [
    token,
    userId,
    keyword,
    action,
    startDate,
    endDate,
    meta.page,
    meta.per_page,
  ]);

  // API Calls
  const getAuditLogEntry = async () => {
    setLoading(true);

    try {
      const res = await API.GetAuditLog(
        token,
        userId,
        keyword,
        action,
        startDate,
        endDate,
        meta.page,
        meta.per_page
      );

      if (res?.status === 200) {
        const responseData = res.data?.data || [];
        setLogData(responseData);

        if (res.data?.meta) {
          setMeta(res.data.meta);
        }
        setStartIndex((meta.page - 1) * meta.per_page);
        setZeroData(responseData.length === 0 ? "No data available" : "");
      } else {
        setLogData([]);
        setZeroData(res?.message || "No data available");
        setMeta(defaultMeta);
      }
    } catch (error) {
      setLogData([]);
      setZeroData("Error fetching data");
      setMeta(defaultMeta);
    } finally {
      setLoading(false);
    }
  };

  const getUsers = async () => {
    if (!token) return;

    setLoading(true);
    try {
      const resUserData = await API.GetUser(
        token,
        searchQuery,
        "admin",
        1, // Always fetch first page of users for filter dropdown
        100 // Fetch more users at once for the dropdown
      );

      if (resUserData.status === 200 && resUserData.data.data.length > 0) {
        const sortedData = resUserData.data.data.map((user: UserData) => ({
          ...user,
          join_date: formatDate(user.join_date),
          last_activity: timeAgo(user.last_activity),
        }));
        setUserData(sortedData);
      } else {
        setUserData([]);
        setZeroData(resUserData.data.message);
      }
    } catch (error) {
      setUserData([]);
    } finally {
      setLoading(false);
    }
  };

  // Event Handlers
  const handleSearch = (query: string) => {
    setKeyword(query);
    setMeta({
      ...defaultMeta,
      page: 1,
    });
  };

  const handleClearDate = () => {
    setStartDate("");
    setEndDate("");

    if (flatpickrRef.current !== null) {
      flatpickrRef.current.flatpickr.clear();
    }
  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMeta((prevMeta) => ({
        ...prevMeta,
        page: pageNumber,
      }));
    }
  };

  const handleRecordsPerPageChange = (newRecordsPerPage: number) => {
    setMeta((prev) => ({
      ...prev,
      page: 1,
      per_page: newRecordsPerPage,
    }));
  };

  const handleDateChange = (dates: Date[]) => {
    if (dates.length === 2) {
      const formatDate = (date: Date) => date.toISOString().split("T")[0];
      setStartDate(formatDate(dates[0]));
      setEndDate(formatDate(dates[1]));
    } else {
      setStartDate("");
      setEndDate("");
    }
    setMeta((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleFilterChange = (
    selectedAction: string,
    selectedUserId?: number
  ) => {
    setUserId(selectedUserId ? selectedUserId.toString() : "");
    setAction(selectedAction);
    setMeta((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleResetFilter = () => {
    setAction("");
    setUserId("");
    setMeta((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  // Render
  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Audit Log</h3>
        </div>
      </div>

      <div className="card-body border-top">
        <div className="card-header border-0">
          <div className="card-toolbar d-flex flex-wrap gap-4 w-100 justify-content-between align-items-center">
            <div className="d-flex flex-wrap gap-4 align-items-center">
              {/* Date Range Picker */}
              <div className="d-flex align-items-center">
                <div className="input-group input-group-sm w-300px">
                  <Flatpickr
                    ref={flatpickrRef}
                    className="form-control form-control-solid"
                    placeholder="Pilih rentang waktu"
                    options={{
                      mode: "range",
                      locale: Indonesian,
                      altInput: true,
                      altFormat: "d F Y",
                      dateFormat: "Y-m-d",
                    }}
                    onChange={handleDateChange}
                  />
                  <button
                    className="btn btn-icon btn-light"
                    onClick={handleClearDate}
                    type="button"
                  >
                    <i className="bi bi-x fs-2"></i>
                  </button>
                </div>
              </div>
              <FilterAction
                onChange={handleFilterChange}
                onReset={handleResetFilter}
                users={userData}
              />
            </div>
            <div className="d-flex align-items-center position-relative w-250px">
              <span className="position-absolute ms-4">
                <i className="bi bi-search fs-4 text-gray-500"></i>
              </span>
              <input
                type="text"
                className="form-control form-control-solid ps-12"
                placeholder="Search..."
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="fw-bold border-bottom border-gray-200">
                <th className="min-w-60px text-center">#</th>
                <th className="min-w-125px">Pengguna</th>
                <th className="min-w-125px">Role</th>
                <th className="min-w-125px">Aksi</th>
                <th className="min-w-400px">Deskripsi</th>
                <th className="min-w-125px">Dilakukan Pada</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6} className="text-center p-5">
                    <Spin size="default" />
                  </td>
                </tr>
              ) : logData.length > 0 ? (
                logData.map((log: AuditLogEntry, index: number) => (
                  <tr key={log.id}>
                    <td className="text-center fw-semibold">
                      {startIndex + index + 1}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-30px me-3">
                          {/* User initial icon if needed */}
                        </div>
                        <span className="fw-semibold">{log.user.name}</span>
                      </div>
                    </td>
                    <td>
                      <span className="badge badge-light-info fw-semibold">
                        {log.user.role}
                      </span>
                    </td>
                    <td>
                      <span className="badge badge-light-primary fw-semibold">
                        {log.action}
                      </span>
                    </td>
                    <td>{formatDescription(log.description)}</td>
                    <td>
                      <div className="fw-semibold">
                        {formatDate(log.performed_at)}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center p-5">
                    {zeroData ? (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 60 }}
                        description={
                          <span className="text-muted fw-bold">{zeroData}</span>
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Fixed pagination component */}
          {!loading && logData.length > 0 && (
            <Pagination
              totalRecords={meta.total}
              recordsPerPage={meta.per_page}
              currentPage={meta.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          )}
        </div>
      </div>

      <style>{`
.description-container {
  max-width: 100%;
  overflow-x: auto;
}
.json-container {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  font-size: 13px;
  background-color: var(--kt-gray-100) !important;
  color: var(--kt-text-gray-800) !important;
  border-color: var(--kt-gray-200) !important;
}
.json-container pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: transparent !important;
}
.table {
  --bs-table-color: var(--kt-text-gray-800);
  --bs-table-bg: var(--kt-body-bg);
  --bs-table-border-color: var(--kt-gray-200);
  --bs-table-hover-bg: var(--kt-gray-100);
  --bs-table-striped-bg: var(--kt-gray-100);
}
.table th {
  font-weight: 600 !important;
  color: var(--kt-text-gray-800);
  border-bottom-color: var(--kt-gray-200) !important;
}
.table td {
  color: var(--kt-text-gray-700);
  border-bottom-color: var(--kt-gray-200) !important;
}
.badge {
  padding: 0.5rem 0.85rem;
}
.badge-light-primary {
  color: var(--kt-primary) !important;
  background-color: var(--kt-primary-light) !important;
}
.badge-light-info {
  color: var(--kt-info) !important;
  background-color: var(--kt-info-light) !important;
}
.symbol-label {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--kt-primary-light) !important;
  color: var(--kt-primary) !important;
  border-radius: 0.475rem;
}
.card {
  background-color: var(--kt-body-bg);
  border: 1px solid var(--kt-card-border-color);
}
.card-header {
  background-color: var(--kt-body-bg) !important;
  border-bottom: 1px solid var(--kt-card-border-color);
}
.form-control.form-control-solid {
  background-color: var(--kt-gray-100);
  border-color: var(--kt-gray-100);
  color: var(--kt-text-gray-800);
  transition: color 0.2s ease;
}
.form-control.form-control-solid:focus {
  background-color: var(--kt-gray-100);
  border-color: var(--kt-primary-light);
  color: var(--kt-text-gray-800);
}
.form-control.form-control-solid::placeholder {
  color: var(--kt-text-muted);
}
.btn-light-danger {
  color: var(--kt-danger);
  background-color: var(--kt-danger-light);
}
.btn-light-danger:hover {
  color: var(--kt-white);
  background-color: var(--kt-danger);
}
.btn-outline-primary {
  color: var(--kt-primary);
  border-color: var(--kt-primary);
}
.btn-outline-primary:hover {
  color: var(--kt-white);
  background-color: var(--kt-primary);
  border-color: var(--kt-primary);
}
.text-muted {
  color: var(--kt-text-muted) !important;
}
.border {
  border-color: var(--kt-border-color) !important;
}
.border-gray-200 {
  border-color: var(--kt-gray-200) !important;
}
.border-bottom {
  border-bottom-color: var(--kt-border-color) !important;
}
.shadow-sm {
  box-shadow: var(--kt-shadow-sm) !important;
}

/* Flatpickr Dark Mode Styles */
[data-theme="dark"] {
  .flatpickr-calendar {
    background-color: var(--kt-body-bg);
    border-color: var(--kt-border-color);
    box-shadow: var(--kt-shadow);
  }
  .flatpickr-day {
    color: var(--kt-text-gray-800);
    &:hover {
      background-color: var(--kt-gray-100);
    }
    &.selected {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }
  .flatpickr-months, .flatpickr-weekdays {
    background-color: var(--kt-body-bg);
  }
  .flatpickr-current-month, .flatpickr-monthDropdown-months {
    color: var(--kt-text-gray-800);
  }
  .flatpickr-weekday {
    color: var(--kt-text-muted);
  }
}

/* Custom Scrollbar */
.description-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.description-container::-webkit-scrollbar-track {
  background-color: var(--kt-gray-100);
  border-radius: 3px;
}
.description-container::-webkit-scrollbar-thumb {
  background-color: var(--kt-gray-300);
  border-radius: 3px;
}
.description-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--kt-gray-400);
}

/* Empty State */
.ant-empty {
  color: var(--kt-text-muted);
}
.ant-empty-description {
  color: var(--kt-text-muted);
}

/* Loading Spinner */
.ant-spin {
  color: var(--kt-primary);
}
.ant-spin-dot-item {
  background-color: var(--kt-primary);
}
`}</style>
    </div>
  );
};

export { AuditLogPage };
