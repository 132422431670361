/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {AllProducts} from '../../modules/marketplace/products/AllProduct'
import usePageTitle from '../../modules/global/PageTitle'
import {QueryRequestProvider} from '../../modules/marketplace/user-management/users-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../modules/marketplace/user-management/users-list/core/QueryResponseProvider'
import {ListViewProvider} from '../../modules/marketplace/user-management/users-list/core/ListViewProvider'

const AllProdukPage: FC = () => (
  <>
    <div className='card mb-xl-10'>
      <AllProducts className='mb-xl-8' />
    </div>
  </>
)

const AllProdukWrapper: FC = () => {
  const intl = useIntl()
  usePageTitle('Produk')

  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <AllProdukPage />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {AllProdukWrapper}
