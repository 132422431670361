import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";
import API from "../../../../../utils/apiProvider";
import { Pagination } from "../../../global/Pagination";
import RupiahFormat from "../../../global/Formatrupiah";
import { Link, useLocation } from "react-router-dom";
import { Empty, Image, Spin } from "antd";
import { DetailTransactionUser } from "./modal/DetailTransactionUser";
import { formatToRupiah } from "../../../../../utils/formatters";

// Types definitions
interface Product {
  id: number;
  photo: string;
  name: string;
  variant: string | null;
  qty: number;
}

interface Transaction {
  id: number;
  code: string;
  products: Product[];
  grand_total: number;
  status: string;
  date: string;
}

interface ExpeditionPayment {
  id: number;
  trx_id: string;
  courier: string;
  service: string;
  amount: number;
  status: string;
  date: string;
}

interface BalanceHistory {
  id: number;
  trx_id: string;
  ref_id: string;
  type: "debit" | "credit";
  code: string;
  amount: number;
  balance_before: number;
  balance_after: number;
  remark: string;
  date: string;
}

interface UserTransactionData {
  transactions: Transaction[];
  expedition_payments: ExpeditionPayment[];
  balance_histories: BalanceHistory[];
}

interface MetaData {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

type Props = {
  className?: string;
  name: string;
  id: number;
};

// Tab types
type TabType = "transactions" | "expedition" | "balance";

const UserTransactionTabs: React.FC<Props> = ({ className = "", name, id }) => {
  const location = useLocation();
  const isExpedition = location.pathname.includes("/ekspedisi");

  // Initial active tab depends on user type
  const initialTabType: TabType = isExpedition ? "expedition" : "transactions";

  // State management
  const [activeTab, setActiveTab] = useState<TabType>(initialTabType);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<UserTransactionData>({
    transactions: [],
    expedition_payments: [],
    balance_histories: [],
  });
  const [zeroData, setZeroData] = useState("");

  // Pagination states for tabs
  const [transactionMeta, setTransactionMeta] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const [expeditionMeta, setExpeditionMeta] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const [balanceMeta, setBalanceMeta] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });

  const { currentUser } = useAuth();
  const token = currentUser?.token;

  // Fetch user transaction data
  useEffect(() => {
    const fetchTransactionData = async () => {
      if (!token || id === 0) {
        console.error("No token or invalid user ID");
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const response = await API.GetTransactionUser(token, id);

        if (response.status === 200) {
          const data = response.data.data;

          // Set user transaction data
          setUserData({
            transactions: data.transactions || [],
            expedition_payments: data.expedition_payments || [],
            balance_histories: data.balance_histories || [],
          });

          // Set meta data for transactions
          if (data.transactions && data.transactions.length > 0) {
            setTransactionMeta({
              ...transactionMeta,
              total: data.transactions.length,
              total_pages: Math.ceil(
                data.transactions.length / transactionMeta.per_page
              ),
            });
          }

          // Set meta data for expedition payments
          if (data.expedition_payments && data.expedition_payments.length > 0) {
            setExpeditionMeta({
              ...expeditionMeta,
              total: data.expedition_payments.length,
              total_pages: Math.ceil(
                data.expedition_payments.length / expeditionMeta.per_page
              ),
            });
          }

          // Set meta data for balance histories
          if (data.balance_histories && data.balance_histories.length > 0) {
            setBalanceMeta({
              ...balanceMeta,
              total: data.balance_histories.length,
              total_pages: Math.ceil(
                data.balance_histories.length / balanceMeta.per_page
              ),
            });
          }

          // Set zero data message based on user type
          if (isExpedition) {
            if (
              (!data.expedition_payments ||
                data.expedition_payments.length === 0) &&
              (!data.balance_histories || data.balance_histories.length === 0)
            ) {
              setZeroData("Tidak ada data pembayaran atau riwayat saldo.");
            }
          } else {
            if (
              (!data.transactions || data.transactions.length === 0) &&
              (!data.balance_histories || data.balance_histories.length === 0)
            ) {
              setZeroData("Tidak ada data transaksi atau riwayat saldo.");
            }
          }
        } else {
          setZeroData(response.data.message || "Tidak ada data.");
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setZeroData("Gagal memuat data. Silakan coba lagi.");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, [token, id]);

  // Pagination handlers
  const handleTransactionPageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= transactionMeta.total_pages) {
      setTransactionMeta((prev) => ({
        ...prev,
        page: pageNumber,
      }));
    }
  };

  const handleTransactionRecordsPerPageChange = (recordsPerPage: number) => {
    setTransactionMeta((prev) => ({
      ...prev,
      page: 1,
      per_page: recordsPerPage,
      total_pages: Math.ceil(prev.total / recordsPerPage),
    }));
  };

  const handleExpeditionPageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= expeditionMeta.total_pages) {
      setExpeditionMeta((prev) => ({
        ...prev,
        page: pageNumber,
      }));
    }
  };

  const handleExpeditionRecordsPerPageChange = (recordsPerPage: number) => {
    setExpeditionMeta((prev) => ({
      ...prev,
      page: 1,
      per_page: recordsPerPage,
      total_pages: Math.ceil(prev.total / recordsPerPage),
    }));
  };

  const handleBalancePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= balanceMeta.total_pages) {
      setBalanceMeta((prev) => ({
        ...prev,
        page: pageNumber,
      }));
    }
  };

  const handleBalanceRecordsPerPageChange = (recordsPerPage: number) => {
    setBalanceMeta((prev) => ({
      ...prev,
      page: 1,
      per_page: recordsPerPage,
      total_pages: Math.ceil(prev.total / recordsPerPage),
    }));
  };

  // Modal handlers
  const handleOpenModal = (transactionId: number) => {
    setSelectedTransactionId(transactionId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTransactionId(null);
  };

  // Helper function to format date
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  };

  // Get paginated data for current view
  const getPaginatedTransactions = () => {
    const startIndex = (transactionMeta.page - 1) * transactionMeta.per_page;
    const endIndex = startIndex + transactionMeta.per_page;
    return userData.transactions.slice(startIndex, endIndex);
  };

  const getPaginatedExpeditionPayments = () => {
    const startIndex = (expeditionMeta.page - 1) * expeditionMeta.per_page;
    const endIndex = startIndex + expeditionMeta.per_page;
    return userData.expedition_payments.slice(startIndex, endIndex);
  };

  const getPaginatedBalanceHistories = () => {
    const startIndex = (balanceMeta.page - 1) * balanceMeta.per_page;
    const endIndex = startIndex + balanceMeta.per_page;
    return userData.balance_histories.slice(startIndex, endIndex);
  };

  // Get selected transaction for modal
  const selectedTransaction = selectedTransactionId
    ? userData.transactions.find((tx) => tx.id === selectedTransactionId)
    : null;

  // Render functions
  const renderTransactionTab = () => {
    const transactions = getPaginatedTransactions();
    const startIndex = (transactionMeta.page - 1) * transactionMeta.per_page;

    return (
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead>
            <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
              <th className="min-w-60px rounded-start text-center">No</th>
              <th className="min-w-125px">Kode Transaksi</th>
              <th className="min-w-200px">Produk</th>
              <th className="min-w-125px">Total Transaksi</th>
              <th className="min-w-125px">Status</th>
              <th className="min-w-125px">Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6} className="text-center">
                  <Spin size="default" />
                </td>
              </tr>
            ) : transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <tr key={transaction.id}>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {startIndex + index + 1}
                    </span>
                  </td>
                  <td className="text-center">
                    <Link
                      to={`/marketplace/transaksi/detail/${transaction.id}`}
                      state={{ id: transaction.id }}
                      className="text-primary fw-bold d-block mb-1 fs-6 text-center text-hover-primary"
                    >
                      {transaction.code}
                    </Link>
                  </td>
                  <td>
                    {transaction.products.map((product, idx) => (
                      <div
                        key={idx}
                        className="d-flex align-items-center mb-2 last-mb-0"
                      >
                        <div className="symbol symbol-60px me-3">
                          <span className="symbol-label bg-light">
                            <Image
                              src={product.photo}
                              className="rounded"
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "contain",
                              }}
                            />
                          </span>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="text-dark fw-bold fs-6">
                            {product.name}
                          </span>
                          <span className="text-muted fw-semibold">
                            Qty: {product.qty}
                          </span>
                        </div>
                      </div>
                    ))}
                  </td>
                  <td className="text-center">
                    <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                      {formatToRupiah(transaction.grand_total)}
                    </span>
                  </td>
                  <td className="text-center">
                    <div
                      className={`badge badge-light-${
                        transaction.status === "success" ? "success" : "danger"
                      } fw-bold`}
                    >
                      {transaction.status === "success"
                        ? "Berhasil"
                        : "Dibatalkan"}
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="text-dark text-muted fw-bold d-block mb-1 fs-6">
                      {formatDate(transaction.date)}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center p-5">
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={
                      <span className="text-muted fw-bold">
                        {zeroData || "Tidak ada data transaksi."}
                      </span>
                    }
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {userData.transactions.length > 0 && (
          <Pagination
            totalRecords={userData.transactions.length}
            recordsPerPage={transactionMeta.per_page}
            currentPage={transactionMeta.page}
            onPageChange={handleTransactionPageChange}
            onRecordsPerPageChange={handleTransactionRecordsPerPageChange}
          />
        )}
      </div>
    );
  };

  const renderExpeditionTab = () => {
    const expeditionPayments = getPaginatedExpeditionPayments();
    const startIndex = (expeditionMeta.page - 1) * expeditionMeta.per_page;

    return (
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead>
            <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
              <th className="min-w-60px rounded-start text-center">No</th>
              <th className="min-w-125px text-center">ID Transaksi</th>
              <th className="min-w-125px text-center">Kurir</th>
              <th className="min-w-125px text-center">Layanan</th>
              <th className="min-w-125px text-center">Jumlah</th>
              <th className="min-w-125px text-center">Status</th>
              <th className="min-w-125px rounded-end text-center">Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7} className="text-center">
                  <Spin size="default" />
                </td>
              </tr>
            ) : expeditionPayments.length > 0 ? (
              expeditionPayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {startIndex + index + 1}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {payment.trx_id}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {payment.courier}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {payment.service}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      <RupiahFormat value={payment.amount} />
                    </span>
                  </td>
                  <td className="text-center">
                    <span
                      className={`badge ${
                        payment.status === "success"
                          ? "badge-light-success"
                          : payment.status === "pending"
                          ? "badge-light-warning"
                          : "badge-light-danger"
                      }`}
                    >
                      {payment.status === "success"
                        ? "Berhasil"
                        : payment.status === "pending"
                        ? "Pending"
                        : "Gagal"}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      {formatDate(payment.date)}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="text-center p-5">
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={
                      <span className="text-muted fw-bold">
                        {zeroData || "Tidak ada data transaksi ekspedisi."}
                      </span>
                    }
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {userData.expedition_payments.length > 0 && (
          <Pagination
            totalRecords={userData.expedition_payments.length}
            recordsPerPage={expeditionMeta.per_page}
            currentPage={expeditionMeta.page}
            onPageChange={handleExpeditionPageChange}
            onRecordsPerPageChange={handleExpeditionRecordsPerPageChange}
          />
        )}
      </div>
    );
  };

  const renderBalanceHistoryTab = () => {
    const balanceHistories = getPaginatedBalanceHistories();
    const startIndex = (balanceMeta.page - 1) * balanceMeta.per_page;

    return (
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead>
            <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
              <th className="min-w-60px rounded-start text-center">No</th>
              <th className="min-w-125px text-center">Tanggal</th>
              <th className="min-w-125px text-center">ID Transaksi</th>
              <th className="min-w-125px text-center">ID Referensi</th>
              <th className="min-w-125px text-center">Tipe</th>
              <th className="min-w-125px text-center">Jumlah</th>
              <th className="min-w-125px text-center">Saldo Sebelum</th>
              <th className="min-w-125px text-center">Saldo Sesudah</th>
              <th className="min-w-200px rounded-end text-center">
                Keterangan
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={9} className="text-center">
                  <Spin size="default" />
                </td>
              </tr>
            ) : balanceHistories.length > 0 ? (
              balanceHistories.map((history, index) => (
                <tr key={history.id}>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {startIndex + index + 1}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      {formatDate(history.date)}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {history.trx_id}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold mb-1 fs-6">
                      {history.ref_id}
                    </span>
                  </td>
                  <td className="text-center">
                    <span
                      className={`badge ${
                        history.type === "credit"
                          ? "badge-light-success"
                          : "badge-light-danger"
                      }`}
                    >
                      {history.type === "credit" ? "Kredit" : "Debit"}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      <RupiahFormat value={history.amount} />
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      <RupiahFormat value={history.balance_before} />
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      <RupiahFormat value={history.balance_after} />
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-dark fw-bold d-block mb-1 fs-6">
                      {history.remark}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center p-5">
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={
                      <span className="text-muted fw-bold">
                        {zeroData || "Tidak ada data histori saldo."}
                      </span>
                    }
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {userData.balance_histories.length > 0 && (
          <Pagination
            totalRecords={userData.balance_histories.length}
            recordsPerPage={balanceMeta.per_page}
            currentPage={balanceMeta.page}
            onPageChange={handleBalancePageChange}
            onRecordsPerPageChange={handleBalanceRecordsPerPageChange}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {isModalOpen && selectedTransaction && (
        <DetailTransactionUser
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          id={selectedTransactionId!}
          data={selectedTransaction as any}
        />
      )}

      <div className={`card mb-xl-8 ${className}`}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Detail Pengguna ({name})
            </span>
          </h3>
        </div>

        {/* Tab Navigation - Different tabs for expedition vs regular users */}
        <div className="card-header border-0 pt-5">
          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
            {!isExpedition && (
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "transactions" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("transactions")}
                >
                  <span className="fw-bold">Riwayat Transaksi</span>
                </button>
              </li>
            )}

            {isExpedition && (
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "expedition" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("expedition")}
                >
                  <span className="fw-bold">Pembayaran Ekspedisi</span>
                </button>
              </li>
            )}

            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "balance" ? "active" : ""
                }`}
                onClick={() => setActiveTab("balance")}
              >
                <span className="fw-bold">Riwayat Saldo</span>
              </button>
            </li>
          </ul>
        </div>

        <div className="separator separator my-4"></div>

        <div className="card-body py-3">
          {/* Tab Content */}
          <div className="tab-content">
            {!isExpedition && (
              <div
                className={`tab-pane fade ${
                  activeTab === "transactions" ? "show active" : ""
                }`}
              >
                {renderTransactionTab()}
              </div>
            )}

            {isExpedition && (
              <div
                className={`tab-pane fade ${
                  activeTab === "expedition" ? "show active" : ""
                }`}
              >
                {renderExpeditionTab()}
              </div>
            )}

            <div
              className={`tab-pane fade ${
                activeTab === "balance" ? "show active" : ""
              }`}
            >
              {renderBalanceHistoryTab()}
            </div>
          </div>
        </div>
      </div>

      <style>{`
        .nav-tabs .nav-link.active {
          color: var(--bs-primary) !important;
          border-bottom: 2px solid var(--bs-primary) !important;
        }
        .nav-tabs .nav-link {
          border: none;
          border-bottom: 2px solid transparent;
          transition: color 0.2s ease, border-color 0.2s ease;
        }
        .nav-tabs .nav-link:hover {
          border-color: transparent;
          color: var(--bs-primary);
        }
        .table th {
          font-weight: 600 !important;
          color: var(--bs-text-muted);
        }
        .table td {
          vertical-align: middle;
        }
        .badge {
          font-weight: 600;
          padding: 0.5rem 0.75rem;
        }
      `}</style>
    </>
  );
};

export { UserTransactionTabs };
