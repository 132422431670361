import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddUser: (formData: any) => void;
  formType: "admin" | "manager" | "accounting" | "admin_exp" | "exp";
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  isOpen,
  onClose,
  handleAddUser,
  formType,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    ...(formType === "exp" && { code: "" }),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  const handleClose = () => {
    setFormData({
      name: "",
      phone: "",
      email: "",
      password: "",
      ...(formType === "exp" && { code: "" }),
    });
    onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          <div
            className="modal fade show d-block"
            id="kt_modal_add_user"
            role="dialog"
            tabIndex={-1}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="fw-bolder">
                    {formType === "manager"
                      ? "Tambah Manager"
                      : formType === "accounting"
                      ? "Tambah Accounting"
                      : formType === "admin_exp"
                      ? "Tambah Admin Ekspedisi"
                      : formType === "admin"
                      ? "Tambah Admin"
                      : "Tambah Ekspedisi"}
                  </h2>
                  <button
                    type="button"
                    className="btn btn-icon btn-sm btn-active-icon-primary"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon-1"
                    />
                  </button>
                </div>
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <form id="kt_modal_add_user_form" className="form" noValidate>
                    <div
                      className="d-flex flex-column scroll-y me-n7 pe-7"
                      id="kt_modal_add_user_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_user_header"
                      data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      {formType === "exp" && (
                        <>
                          <div className="fv-row mb-7">
                            <label className="required fw-bold fs-6 mb-2">
                              Kode Ekspedisi
                            </label>
                            <input
                              placeholder="Masukkan kode ekspedisi"
                              className={clsx(
                                "form-control form-control-solid mb-3 mb-lg-0"
                              )}
                              type="text"
                              value={formData.code}
                              name="code"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </div>
                        </>
                      )}
                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Nama
                        </label>
                        <input
                          placeholder="Masukkan nama"
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          autoComplete="off"
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Nomor Telepon
                        </label>
                        <input
                          placeholder="Masukkan nomor telepon"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Email
                        </label>
                        <input
                          placeholder="Masukkan email"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          type="email"
                          value={formData.email}
                          name="email"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>

                      <div className="fv-row mb-7">
                        <label className="required fw-bold fs-6 mb-2">
                          Password
                        </label>
                        <input
                          placeholder="Masukkan password"
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0"
                          )}
                          type="password"
                          value={formData.password}
                          name="password"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      await handleAddUser(formData);
                      onClose();
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
};

export { AddUserModal };
