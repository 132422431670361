// core/Auth.tsx
import {
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { WithChildren } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => Promise<any>;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: async () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(
    auth?.user
  );

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
      setCurrentUser({ token: auth.token || "", refreshToken: auth.refreshToken || "", role: auth.role || "" });
    } else {
      authHelper.removeAuth();
      setCurrentUser(undefined);
    }
  };

  const logout = async () => {
    try {
      if (auth && auth.token && auth.refreshToken) {
        const refreshToken = {
          refresh_token: auth.refreshToken || ""
        };

        const res = await API.Logout(auth.token, refreshToken);

        if (res.status === 200) {
          saveAuth(undefined);
        }
      }
    } catch (error) {
      console.error("Error while logging out:", error);
      saveAuth(undefined);
    }
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const logoutTimer = useRef<NodeJS.Timeout | null>(null);

  const resetLogoutTimer = () => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
      logoutTimer.current = null;
    }
  };

  const startLogoutTimer = () => {
    resetLogoutTimer();
    const thirtyMinutes = 30 * 60 * 1000;
    logoutTimer.current = setTimeout(() => {
      logout();
    }, thirtyMinutes);
  };

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        if (!didRequest.current && auth?.token) {
          setCurrentUser({ token: auth.token || "", refreshToken: auth.refreshToken || "", role: auth.role || "" });
          startLogoutTimer();
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
    };

    verifyAuth();

    return () => {
      didRequest.current = true;
      resetLogoutTimer();
    };
  }, [auth, logout, setCurrentUser]);

  useEffect(() => {
    const handleUserActivity = () => {
      if (auth?.token) {
        startLogoutTimer();
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        resetLogoutTimer();
      } else if (auth?.token) {
        startLogoutTimer();
      }
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    if (auth?.token) {
      startLogoutTimer();
    }

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      resetLogoutTimer();
    };
  }, [auth?.token]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
