import React, { useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { useAuth } from "../../auth";
import API from "../../../../utils/apiProvider";
import { AddKategoriModal } from "./modal/AddKategoriModal";
import { UpdateKategoriModal } from "./modal/UpdateKategoriModal";
import { FilterCategory } from "./filter/FilterCategory";
import { DeleteModal } from "../../global/DeleteModal";
import { Pagination } from "../../global/Pagination";
import { AlertMessage } from "../../global/AlertMessage";
import { Empty, Image, Spin } from "antd";
import SearchInput from "../../global/SearchInput";

type Props = {
  className: string;
};

type CategoryData = {
  id: number;
  type: string;
  name: string;
  icon: string;
  parent_id: number;
  is_active: boolean;
  child_count: string;
  childs: any[];
};

type MetaData = {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
};

const MainCategory: React.FC<Props> = ({ className }) => {
  const level = "main";
  const parent_id = "";
  const { currentUser } = useAuth();
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [zeroData, setZeroData] = useState("");
  const token = currentUser?.token;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [startIndex, setStartIndex] = useState(0);

  const GetCategory = async () => {
    setLoading(true);
    const resDataCategory = await API.GetCategory(
      token,
      searchQuery,
      level,
      parent_id,
      meta.page,
      filterType,
      meta.per_page
    );
    if (
      resDataCategory.status === 200 &&
      resDataCategory.data.data.length > 0
    ) {
      setCategoryData(resDataCategory.data.data);
      setMetaData(resDataCategory.data.meta);
      setZeroData("");
    } else {
      setCategoryData([]);
      setMetaData({ page: 1, per_page: 10, total: 0, total_pages: 0 });
      setZeroData(resDataCategory.data.message);
    }
    setLoading(false);
  };

  function handlePageChange(pageNumber: number) {
    if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        page: pageNumber,
      }));
    }
  }

  const handleRecordsPerPageChange = (recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  };

  const handleModal = (type: "add" | "update", isOpen: boolean) => {
    switch (type) {
      case "add":
        setIsModalOpen(isOpen);
        break;
      case "update":
        setIsUpdateModalOpen(isOpen);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  };

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [meta.page, meta.per_page]);

  useEffect(() => {
    if (token) {
      GetCategory();
    }
  }, [token, meta.page, filterType, searchQuery, meta.per_page]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
    return () => clearTimeout(timeout as NodeJS.Timeout);
  }, [showAlert]);

  const handleAddCategory = async (formData: any) => {
    setLoading(true);

    const { name, type, icon } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("level", level);
    formDataAPI.append("type", type);
    formDataAPI.append("name", name);
    formDataAPI.append("icon", icon);

    const resAddCategory = await API.AddCategory(formDataAPI, token);

    if (resAddCategory.status === 200) {
      await GetCategory();
      setShowAlert(true);
      setSuccessMessage(resAddCategory.data.message);
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage(resAddCategory.data.message);
    }
  };

  const handleUpateCategory = async (formData: any, id: number) => {
    setLoading(true);

    const { name, type, icon } = formData;

    const formDataAPI = new FormData();
    formDataAPI.append("level", level);
    formDataAPI.append("type", type);
    formDataAPI.append("name", name);
    if (icon instanceof File) {
      formDataAPI.append("icon", icon);
    }

    const resUpdateCategory = await API.UpdateCategory(formDataAPI, token, id);

    if (resUpdateCategory.status === 200) {
      await GetCategory();
      setShowAlert(true);
      setSuccessMessage(resUpdateCategory.data.message);
      setLoading(false);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateCategory.data.message);
    }
  };

  const handleDeleteCategory = async (id: number) => {
    const resDeleteCategory = await API.DeleteCategory(token, id);
    if (resDeleteCategory.status === 200) {
      await GetCategory();
      setShowAlert(true);
      setSuccessMessage(resDeleteCategory.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resDeleteCategory.data.message);
    }
  };

  const handleToggleActive = async (id: number) => {
    const resUpdateStatusCategory = await API.UpdateStatusCategory(id, token);
    if (resUpdateStatusCategory.status === 200) {
      setCategoryData((prevUserData) => {
        return prevUserData.map((category) => {
          if (category.id === id) {
            return { ...category, is_active: !category.is_active };
          }
          return category;
        });
      });
      setShowAlert(true);
      setSuccessMessage(resUpdateStatusCategory.data.message);
    } else {
      setShowAlertError(true);
      setErrorMessage(resUpdateStatusCategory.data.message);
    }
  };

  const getCategoryType = (type: string): string => {
    if (type === "material") {
      return "Material";
    } else if (type === "non-material") {
      return "Non-Material";
    } else if (type === "ppob") {
      return "PPOB";
    } else {
      return "";
    }
  };

  const handleFilterChange = (value: string | "") => {
    setFilterType(value);
  };

  const handleResetFilter = () => {
    setFilterType("");
  };

  return (
    <>
      <div className={`card ${className}`}>
        {showAlertError && errorMessage && (
          <AlertMessage type="error" message={errorMessage} />
        )}

        {showAlert && successMessage && (
          <AlertMessage type="success" message={successMessage} />
        )}

        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">List Kategori</h3>
          </div>
        </div>
        <div className="separator separator"></div>

        <div className="card-header border-0 pt-6">
          <div className="d-flex align-items-center position-relative">
            <button
              type="button"
              className="btn btn-sm btn-light-primary me-4"
              onClick={() => handleModal("add", true)}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              Tambah Kategori
            </button>
            {/* <div className='card-toolbar'>
              <div className='d-flex justify-content-end me-4' data-kt-user-table-toolbar='base'>
                <input
                  type='file'
                  ref={fileInputRef}
                  className='form-control me-3 d-none'
                  onChange={handleFileChange}
                  accept='.xlsx'
                />
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary'
                  onClick={openFilePicker}
                >
                  <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2' />
                  Impor Kategori
                </button>
              </div>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary'
                  onClick={handleExportCategory}
                >
                  <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
                  Ekspor Kategori
                </button>
              </div>
            </div> */}
          </div>

          <div className="card-toolbar d-flex align-items-center">
            <FilterCategory
              onChange={handleFilterChange}
              onReset={handleResetFilter}
            />
            <div className="d-flex align-items-center position-relative">
              <SearchInput placeholder="Search" onSearch={handleSearch} />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <AddKategoriModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            handleAddCategory={handleAddCategory}
          />
        )}

        {isUpdateModalOpen && (
          <UpdateKategoriModal
            isOpen={isUpdateModalOpen}
            onClose={handleCloseModal}
            handleUpateCategory={handleUpateCategory}
            id={selectedCategoryId}
            category={categoryData.find(
              (category) => category.id === selectedCategoryId
            )}
          />
        )}
        <div className="card-body py-3">
          <div className="table-responsive">
            <table
              id="kt_table_users"
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light text-center">
                  <th className="min-w-60px rounded-start">No</th>
                  <th className="min-w-125px">Ikon</th>
                  <th className="min-w-125px">Nama</th>
                  <th className="min-w-125px">Tipe</th>
                  <th className="min-w-125px">Induk</th>
                  <th className="min-w-125px">Sub Kategori</th>
                  <th className="min-w-125px">Status</th>
                  <th className="pe-4 min-w-20px rounded-end">Aksi</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      <Spin size="default" />
                    </td>
                  </tr>
                ) : categoryData.length > 0 ? (
                  categoryData.map((category, index) => (
                    <tr key={category.id}>
                      <td className="text-center">
                        <span className="text-dark fw-bold mb-1 fs-6">
                          {startIndex + index + 1}
                        </span>
                      </td>
                      <td className="text-center">
                        <div className="symbol symbol-60px">
                          <span className="symbol-label bg-light">
                            <Image
                              src={
                                category.icon ||
                                toAbsoluteUrl(
                                  "/media/svg/avatars/blank-image.svg"
                                )
                              }
                              className="rounded"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "contain",
                              }}
                            />
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bold">
                              {category.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <span
                          className={`badge badge-light-${
                            category.type === "material"
                              ? "danger"
                              : category.type === "ppob"
                              ? "success"
                              : "primary"
                          } fs-7 fw-semibold`}
                        >
                          {getCategoryType(category.type)}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="text-muted fw-bold mb-1 fs-6">
                          {category.child_count} Sub Kategori
                        </span>
                      </td>
                      <td className="text-center">
                        <Link
                          to="/marketplace/sub-kategori"
                          state={{
                            subCategories: category.childs || [],
                            type: category.type,
                            parent_id: category.id,
                            name: category.name,
                          }}
                          className="btn btn-sm btn-light-primary"
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen010.svg"
                            className="svg-icon-2"
                          />
                          Sub Kategori
                        </Link>
                      </td>
                      <td>
                        <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
                          <input
                            className="form-check-input h-30px w-60px"
                            checked={category.is_active}
                            onClick={() => handleToggleActive(category.id)}
                            type="checkbox"
                            value=""
                            id={`flexSwitch${category.id}`}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <button
                          onClick={() => {
                            setSelectedCategoryId(category.id);
                            handleModal("update", true);
                          }}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <button
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 mb-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target={`#kt_modal_${category.id}`}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3"
                          />
                        </button>
                        <DeleteModal
                          id={category.id}
                          name={`Kategori ${category.name}`}
                          onDelete={handleDeleteCategory}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-center text-muted fw-bold fs-6"
                    >
                      {zeroData ? (
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{ height: 60 }}
                          description={
                            <span className="text-muted fw-bold">
                              {zeroData}
                            </span>
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              totalRecords={meta.total}
              recordsPerPage={meta.per_page}
              currentPage={meta.page}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { MainCategory };
