/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import API from "../../../../utils/apiProvider";
import { AlertMessage } from "../../global/AlertMessage";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export function Login() {
  const [loading, setLoading] = useState(false);
  const { setCurrentUser, saveAuth } = useAuth();
  const [showAlertError, setShowAlertError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deviceInfo, setDeviceInfo] = useState({
    deviceModel: "",
    osVersion: "",
    platform: "",
    userAgent: "",
  });

  const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    let osVersion = "Unknown";
    if (userAgent.indexOf("Windows") !== -1) {
      osVersion = userAgent.match(/Windows NT (\d+\.\d+)/)?.[1] || "Unknown";
    } else if (userAgent.indexOf("Mac") !== -1) {
      osVersion =
        userAgent.match(/Mac OS X (\d+[._]\d+)/)?.[1]?.replace("_", ".") ||
        "Unknown";
    } else if (userAgent.indexOf("Linux") !== -1) {
      osVersion = "Linux";
    }

    let deviceModel = "Unknown";
    if (userAgent.indexOf("Chrome") !== -1) {
      deviceModel =
        "Chrome " + (userAgent.match(/Chrome\/(\d+\.\d+)/)?.[1] || "");
    } else if (userAgent.indexOf("Firefox") !== -1) {
      deviceModel =
        "Firefox " + (userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1] || "");
    } else if (userAgent.indexOf("Safari") !== -1) {
      deviceModel =
        "Safari " + (userAgent.match(/Version\/(\d+\.\d+)/)?.[1] || "");
    } else if (userAgent.indexOf("Edge") !== -1) {
      deviceModel = "Edge " + (userAgent.match(/Edge\/(\d+\.\d+)/)?.[1] || "");
    }

    return {
      deviceModel,
      osVersion,
      platform,
      userAgent,
    };
  };

  useEffect(() => {
    const info = getDeviceInfo();
    setDeviceInfo(info);
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showAlertError) {
      timeout = setTimeout(() => {
        setShowAlertError(false);
      }, 4000);
    }
    return () => clearTimeout(timeout);
  }, [showAlertError]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      const subscriptionId = localStorage.getItem("oneSignalSubscriptionId");

      const formData = new FormData();
      formData.append("auth", values.email);
      formData.append("password", values.password);
      formData.append("fcm_token", subscriptionId || "");
      formData.append("model_device", deviceInfo.deviceModel);
      formData.append("os_version", deviceInfo.osVersion);

      const login = await API.Login(formData);

      if (
        login.status === 200 &&
        (login.data.data.role === "admin" ||
          login.data.data.role === "super_admin")
      ) {
        setCurrentUser({
          token: login.data.data.token,
          role: login.data.data.role,
        });
        saveAuth({ token: login.data.data.token, refreshToken: login.data.data.refresh_token, role: login.data.data.role });
      } else {
        setErrorMessage(login.data.message);
        setShowAlertError(true);
      }
      setLoading(false);
      setSubmitting(false);
    },
  });

  return (
    <div>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Masuk</h1>
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            placeholder="Masukkan email"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": formik.touched.email && formik.errors.email },
              { "is-valid": formik.touched.email && !formik.errors.email }
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Password
          </label>
          <input
            placeholder="Masukkan password"
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              { "is-valid": formik.touched.password && !formik.errors.password }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting || !formik.isValid || loading}
          >
            {!loading && <span className="indicator-label">Masuk</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
