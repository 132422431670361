import React, { useEffect, useState, useCallback, useMemo } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import API from "../../../../utils/apiProvider";
import { useAuth } from "../../auth";
import { AddUserModal } from "./modal/AddUserModal";
import * as Yup from "yup";
import { AlertMessage } from "../../global/AlertMessage";
import { Pagination } from "../../global/Pagination";
import { DeleteModal } from "../../global/DeleteModal";
import Button from "../../global/Button";
import { Link } from "react-router-dom";
import { UpdateUserModal } from "./modal/UpdateUserModal";
import { Empty, Image, Spin } from "antd";
import SearchInput from "../../global/SearchInput";
import { debounce } from "lodash";

// Types
type UserPageType =
  | "admin"
  | "manager"
  | "accounting"
  | "admin_exp"
  | "exp"
  | "buyer"
  | "seller";

interface UserData {
  id: number;
  photo: string;
  code: string;
  name: string;
  email: string;
  phone: string;
  join_date: string;
  last_activity: string;
  is_active: boolean;
}

interface MetaData {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

// Validation Schema
const addUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nama harus terdiri dari minimal 2 karakter")
    .max(50, "Nama tidak boleh lebih dari 50 karakter")
    .required("Nama wajib diisi"),
  phone: Yup.string()
    .matches(/^\d+$/, "Nomor telepon harus berisi hanya angka")
    .min(10, "Nomor telepon harus terdiri dari minimal 10 digit")
    .max(15, "Nomor telepon tidak boleh lebih dari 15 digit")
    .required("Nomor telepon wajib diisi"),
  email: Yup.string()
    .email("Format email tidak valid")
    .required("Email wajib diisi"),
  password: Yup.string()
    .min(6, "Password harus terdiri dari minimal 6 karakter")
    .max(20, "Password tidak boleh lebih dari 20 karakter")
    .required("Password wajib diisi"),
});

const ALERT_TIMEOUT = 4000;

// Table Header Component
const TableHeader: React.FC<{ pageType: UserPageType }> = ({ pageType }) => (
  <thead>
    <tr className="text-muted fw-bolder fs-7 text-uppercase bg-light text-center">
      <th className="min-w-60px rounded-start">No</th>
      <th className="min-w-125px">Nama</th>
      {pageType === "exp" && <th className="min-w-125px">Kode</th>}
      <th className="min-w-125px">Nomor</th>
      <th className={`min-w-${pageType === "exp" ? "200" : "125"}px`}>
        Aktivitas Terakhir
      </th>
      <th className={`min-w-${pageType === "exp" ? "200" : "125"}px`}>
        Tanggal Bergabung
      </th>
      {pageType === "exp" && <th className="min-w-200px">Setup Harga</th>}
      <th
        className={`min-w-${
          pageType !== "buyer" && pageType !== "seller" ? "60" : "125"
        }px`}
      >
        Status
      </th>
      <th
        className={`pe-6 min-w-${
          pageType !== "buyer" && pageType !== "seller" && pageType !== "exp"
            ? "60"
            : "125"
        }px text-muted rounded-end`}
      >
        Aksi
      </th>
    </tr>
  </thead>
);

// Action Buttons Component
const ActionButtons: React.FC<{
  pageType: UserPageType;
  userId: number;
  userName: string;
  onUpdate: () => void;
  onDelete: () => void;
}> = ({ pageType, userId, userName, onUpdate, onDelete }) => {
  if (pageType === "buyer" || pageType === "seller") {
    return (
      <td className="text-end text-md-center">
        <div className="d-flex justify-content-end justify-content-md-center">
          <Link
            to={
              pageType === "buyer"
                ? "/pengguna/pembeli/detail"
                : "/pengguna/penjual/detail"
            }
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            state={{ id: userId, name: userName }}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen032.svg"
              className="svg-icon-2"
            />
          </Link>
          <button
            onClick={onUpdate}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    );
  }

  if (pageType === "exp") {
    return (
      <td className="text-end text-md-center">
        <div className="d-flex flex-wrap justify-content-end justify-content-md-center gap-1">
          <Link
            to="/pengguna/ekspedisi/detail"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            state={{ id: userId, name: userName }}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen032.svg"
              className="svg-icon-2"
            />
          </Link>
          <button
            onClick={onUpdate}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <button
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            type="button"
            data-bs-toggle="modal"
            data-bs-target={`#kt_modal_${userId}`}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
          <DeleteModal id={userId} name={userName} onDelete={onDelete} />
        </div>
      </td>
    );
  }

  return (
    <td className="text-end text-md-center">
      <div className="d-flex justify-content-end justify-content-md-center gap-1">
        <button
          onClick={onUpdate}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
        >
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </button>
        <button
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
          type="button"
          data-bs-toggle="modal"
          data-bs-target={`#kt_modal_${userId}`}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-3"
          />
        </button>
        <DeleteModal id={userId} name={userName} onDelete={onDelete} />
      </div>
    </td>
  );
};

// User Row Component
const UserRow: React.FC<{
  user: UserData;
  index: number;
  pageType: UserPageType;
  onToggleActive: () => void;
  onUpdate: () => void;
  onDelete: () => void;
}> = ({ user, index, pageType, onToggleActive, onUpdate, onDelete }) => (
  <tr>
    <td className="text-center">
      <span className="text-muted fw-bold fs-6">{index + 1}</span>
    </td>
    <td>
      <div className="d-flex align-items-center">
        <div className="symbol symbol-44px me-5">
          <Image
            src={user.photo}
            className="rounded"
            style={{ width: "50px", height: "50px", objectFit: "contain" }}
          />
        </div>
        <div className="d-flex justify-content-start flex-column">
          <span className="text-dark fw-bold mb-1 fs-6">{user.name}</span>
          <span className="text-muted fw-semibold text-muted d-block fs-7">
            {user.email}
          </span>
        </div>
      </div>
    </td>
    {pageType === "exp" && (
      <td className="text-center">
        <span className="text-muted fw-bold d-block mb-1 fs-6 text-center">
          {user.code}
        </span>
      </td>
    )}
    <td className="text-center">
      <span className="text-muted fw-bold d-block fs-6 text-center">
        {user.phone}
      </span>
    </td>
    <td className="text-center">
      <span className="badge badge-light-primary fs-7 fw-semibold">
        {user.last_activity}
      </span>
    </td>
    <td className="text-center">
      <span className="text-muted fw-bold fs-6">{user.join_date}</span>
    </td>
    {pageType === "exp" && (
      <td className="text-center">
        <Link
          to="/pengguna/ekspedisi/price"
          state={{
            id: user.id,
            name: user.name,
            code: user.code,
          }}
          className="btn btn-sm btn-light-primary"
        >
          <KTSVG
            path="/media/icons/duotune/coding/cod001.svg"
            className="svg-icon-2"
          />
          Setup Harga
        </Link>
      </td>
    )}
    <td>
      <div className="form-check form-switch form-check-custom form-check-solid justify-content-center">
        <input
          className="form-check-input h-30px w-60px"
          type="checkbox"
          checked={user.is_active}
          onChange={onToggleActive}
          id={`flexSwitch${user.id}`}
        />
      </div>
    </td>
    <ActionButtons
      pageType={pageType}
      userId={user.id}
      userName={user.name}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  </tr>
);

// Page Header Component
const PageHeader: React.FC<{
  pageType: UserPageType;
  onAddUser: () => void;
  onExport: () => void;
  onSearch: (query: string) => void;
}> = ({ pageType, onAddUser, onExport, onSearch }) => {
  const getTitle = () => {
    switch (pageType) {
      case "admin":
        return "Admin";
      case "manager":
        return "Manager";
      case "accounting":
        return "Akuntansi";
      case "exp":
        return "Ekspedisi";
      case "admin_exp":
        return "Admin Ekspedisi";
      case "buyer":
        return "Pembeli";
      case "seller":
        return "Penjual";
    }
  };

  return (
    <>
      <div className="card-header border-0 cursor-pointer">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">{`List ${getTitle()}`}</h3>
        </div>
      </div>
      <div className="separator separator"></div>
      <div className="card-header border-0 pt-6">
        <div className="card-toolbar d-flex align-items-center">
          {pageType !== "buyer" && pageType !== "seller" && (
            <div className="d-flex align-items-center position-relative">
              <Button
                onClick={onAddUser}
                iconPath="/media/icons/duotune/arrows/arr075.svg"
                buttonText={`Tambah ${getTitle()}`}
              />
            </div>
          )}
          <div className="d-flex align-items-center position-relative">
            <Button
              onClick={onExport}
              iconPath="/media/icons/duotune/coding/cod002.svg"
              buttonText={`Ekspor ${getTitle()}`}
            />
          </div>
        </div>
        <div className="d-flex align-items-center position-relative">
          <SearchInput onSearch={onSearch} placeholder="Search" />
        </div>
      </div>
    </>
  );
};

// Main Component
const UserMultiPage: React.FC<{ pageType: UserPageType }> = ({ pageType }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<UserData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [meta, setMetaData] = useState<MetaData>({
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 0,
  });
  const [selectUserId, setSelectUserId] = useState<number | null>(null);
  const [zeroData, setZeroData] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const formatDate = useCallback((dateString: string): string => {
    const date = new Date(dateString);
    return date
      .toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace("pukul ", "")
      .replace(/\./g, ":");
  }, []);

  const timeAgo = useCallback((dateString: string): string => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInMinutes = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60)
    );

    if (diffInMinutes < 1) return "Baru saja";
    if (diffInMinutes < 60) return `${diffInMinutes} menit yang lalu`;
    if (diffInMinutes < 1440)
      return `${Math.floor(diffInMinutes / 60)} jam yang lalu`;
    return `${Math.floor(diffInMinutes / 1440)} hari yang lalu`;
  }, []);

  const GetProfile = useCallback(async () => {
    if (!currentUser?.token) return;

    setLoading(true);
    try {
      const resUserData = await API.GetUser(
        currentUser.token,
        searchQuery,
        pageType,
        meta.page,
        meta.per_page
      );

      if (resUserData.status === 200 && resUserData.data.data.length > 0) {
        const sortedData = resUserData.data.data.map((user: UserData) => ({
          ...user,
          join_date: formatDate(user.join_date),
          last_activity: timeAgo(user.last_activity),
        }));
        setMetaData(resUserData.data.meta);
        setUserData(sortedData);
      } else {
        setUserData([]);
        setZeroData(resUserData.data.message);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentUser?.token,
    searchQuery,
    pageType,
    meta.page,
    meta.per_page,
    formatDate,
    timeAgo,
  ]);

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        setSearchQuery(query);
      }, 300),
    []
  );

  useEffect(() => {
    GetProfile();
    const newIndex = (meta.page - 1) * meta.per_page;
    setStartIndex(newIndex);
  }, [GetProfile, meta.page, meta.per_page]);

  useEffect(() => {
    if (showAlert || showAlertError) {
      const timeout = setTimeout(() => {
        showAlert ? setShowAlert(false) : setShowAlertError(false);
      }, ALERT_TIMEOUT);
      return () => clearTimeout(timeout);
    }
  }, [showAlert, showAlertError]);

  const handleAddUser = async (formData: any) => {
    setLoading(true);
    try {
      await addUserSchema.validate(formData, { abortEarly: false });
      const formDataAPI = new FormData();
      formDataAPI.append("role", pageType);

      // Only include code for expedition type
      Object.entries(formData).forEach(([key, value]) => {
        if (pageType === "exp" || key !== "code") {
          formDataAPI.append(key, value as string);
        }
      });

      const resAddUser = await API.AddUser(formDataAPI, currentUser?.token);
      if (resAddUser.status === 200) {
        await GetProfile();
        setShowAlert(true);
        setSuccessMessage(resAddUser.data.message);
        setIsModalOpen(false);
      } else {
        setShowAlertError(true);
        setErrorMessage(resAddUser.data.message);
      }
    } catch (error) {
      setShowAlertError(true);
      setErrorMessage("Validation failed");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async (formData: any, id: number) => {
    setLoading(true);
    const formDataAPI = new FormData();
    if (pageType === "exp") {
      Object.entries(formData).forEach(([key, value]) => {
        formDataAPI.append(key, String(value));
      });
    } else {
      const { code, is_cod, ...rest } = formData;
      Object.entries(rest).forEach(([key, value]) => {
        formDataAPI.append(key, value as string);
      });
    }

    const resUpdateUser = await API.UpdateUser(
      formDataAPI,
      currentUser?.token,
      id
    );
    if (resUpdateUser.status === 200) {
      setSuccessMessage(resUpdateUser.data.message);
      setShowAlert(true);
      await GetProfile();
      setIsUpdateModalOpen(false);
    } else {
      setErrorMessage(resUpdateUser.data.message);
      setShowAlertError(true);
    }
    setLoading(false);
  };

  const handleToggleActive = async (id: number) => {
    try {
      const resUpdateStatusUser = await API.UpdateStatusUser(
        currentUser?.token,
        id
      );
      if (resUpdateStatusUser.status === 200) {
        setUserData((prevData) =>
          prevData.map((user) =>
            user.id === id ? { ...user, is_active: !user.is_active } : user
          )
        );
        setShowAlert(true);
        setSuccessMessage(resUpdateStatusUser.data.message);
      } else {
        setShowAlertError(true);
        setErrorMessage(resUpdateStatusUser.data.message);
      }
    } catch (error) {
      console.error("Failed to toggle user status:", error);
      setShowAlertError(true);
      setErrorMessage("Failed to update user status");
    }
  };

  const handleDeleteUser = async (id: number) => {
    setLoading(true);
    try {
      const resDeleteUsers = await API.DeleteUser(currentUser?.token, id);
      if (resDeleteUsers.status === 200) {
        await GetProfile();
        setSuccessMessage(resDeleteUsers.data.message);
        setShowAlert(true);
      } else {
        setShowAlertError(true);
        setErrorMessage(resDeleteUsers.data.message);
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
      setShowAlertError(true);
      setErrorMessage("Failed to delete user");
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      const res = await API.ExportUser(pageType, currentUser?.token);
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `users_${pageType}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setShowAlert(true);
        setSuccessMessage("File berhasil didownload");
      } else {
        setShowAlertError(true);
        setErrorMessage("File gagal didownload");
      }
    } catch (error) {
      console.error("Failed to export users:", error);
      setShowAlertError(true);
      setErrorMessage("Failed to export users");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 1 && pageNumber <= meta.total_pages) {
        setMetaData((prevMetaData) => ({
          ...prevMetaData,
          page: pageNumber,
        }));
      }
    },
    [meta.total_pages]
  );

  const handleRecordsPerPageChange = useCallback((recordsPerPage: number) => {
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      page: 1,
      per_page: recordsPerPage,
    }));
  }, []);

  const renderUserData = useMemo(() => {
    if (loading) {
      return (
        <tr>
          <td colSpan={9} className="text-center">
            <Spin size="default" />
          </td>
        </tr>
      );
    }

    if (userData.length === 0) {
      return (
        <tr>
          <td colSpan={9} className="text-center">
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={
                <span className="text-muted fw-bold">{zeroData}</span>
              }
            />
          </td>
        </tr>
      );
    }

    return userData.map((user, index) => (
      <UserRow
        key={user.id}
        user={user}
        index={startIndex + index}
        pageType={pageType}
        onToggleActive={() => handleToggleActive(user.id)}
        onUpdate={() => {
          setSelectUserId(user.id);
          setIsUpdateModalOpen(true);
        }}
        onDelete={() => handleDeleteUser(user.id)}
      />
    ));
  }, [loading, userData, zeroData, startIndex, pageType]);

  return (
    <>
      {showAlertError && errorMessage && (
        <AlertMessage type="error" message={errorMessage} />
      )}
      {showAlert && successMessage && (
        <AlertMessage type="success" message={successMessage} />
      )}

      <div className="card mb-xl-10">
        <div className="card mb-xl-8">
          <PageHeader
            pageType={pageType}
            onAddUser={() => setIsModalOpen(true)}
            onExport={handleExport}
            onSearch={debouncedSearch}
          />

          <div className="card-body py-3">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-5">
                <TableHeader pageType={pageType} />
                <tbody>{renderUserData}</tbody>
              </table>

              <Pagination
                totalRecords={meta.total}
                recordsPerPage={meta.per_page}
                currentPage={meta.page}
                onPageChange={handlePageChange}
                onRecordsPerPageChange={handleRecordsPerPageChange}
              />
            </div>
          </div>
        </div>
      </div>

      {isModalOpen &&
        (pageType === "manager" ||
          pageType === "admin" ||
          pageType === "accounting" ||
          pageType === "exp" ||
          pageType === "admin_exp") && (
          <AddUserModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            handleAddUser={handleAddUser}
            formType={pageType}
          />
        )}

      {isUpdateModalOpen && (
        <UpdateUserModal
          isOpen={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          handleUpdateUser={handleUpdateUser}
          id={selectUserId}
          user={userData.find((user) => user.id === selectUserId)}
          formType={pageType}
        />
      )}
    </>
  );
};

export { UserMultiPage };
