import React, { useEffect, useState } from "react";
import { useQueryRequest } from "../marketplace/user-management/users-list/core/QueryRequestProvider";
import { useQueryResponse } from "../marketplace/user-management/users-list/core/QueryResponseProvider";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { initialQueryState, KTSVG } from "../../../_metronic/helpers";

interface UserData {
  id: number;
  photo: string;
  code: string;
  name: string;
  email: string;
  phone: string;
  join_date: string;
  last_activity: string;
  is_active: boolean;
}

interface FilterActionProps {
  onChange: (action: string, userId?: number) => void;
  onReset: () => void;
  users: UserData[];
}

const FilterAction: React.FC<FilterActionProps> = ({
  onChange,
  onReset,
  users,
}) => {
  const { updateState } = useQueryRequest();
  const { isLoading } = useQueryResponse();
  const [action, setAction] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<number | "">("");

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const handleReset = () => {
    setAction("");
    setSelectedUserId("");
    onReset();
  };

  const filterData = () => {
    updateState({
      filter: { action, userId: selectedUserId },
      ...initialQueryState,
    });
    onChange(action, selectedUserId ? Number(selectedUserId) : undefined);
  };

  return (
    <>
      <button
        disabled={isLoading}
        type="button"
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-md me-2"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <KTSVG
          path="/media/icons/duotune/general/gen031.svg"
          className="svg-icon-2"
        />
      </button>
      <div
        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
        data-kt-menu="true"
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Opsi Filter</div>
        </div>

        <div className="separator border-gray-200"></div>

        <div className="px-7 py-5" data-kt-user-table-filter="form">
          {/* Filter Pengguna */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Pengguna:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Pilih pengguna"
              data-allow-clear="true"
              data-hide-search="false"
              onChange={(e) =>
                setSelectedUserId(e.target.value ? Number(e.target.value) : "")
              }
              value={selectedUserId}
            >
              <option value="">Pilih pengguna</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>

          {/* Filter Aksi */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">Aksi:</label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Pilih aksi"
              data-allow-clear="true"
              data-kt-user-table-filter="action"
              data-hide-search="true"
              onChange={(e) => setAction(e.target.value)}
              value={action}
            >
              <option value="">Pilih aksi</option>
              <option value="Create">Buat</option>
              <option value="Read">Baca</option>
              <option value="Update">Perbarui</option>
              <option value="Delete">Hapus</option>
            </select>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              disabled={isLoading}
              onClick={handleReset}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset"
            >
              Atur Ulang
            </button>
            <button
              disabled={isLoading}
              type="button"
              onClick={filterData}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
            >
              Terapkan
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { FilterAction };
